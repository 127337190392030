import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BlockUIModule } from 'ng-block-ui';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { CORE_CONFIG } from '@core/constant';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthenticationModule } from './main/authentication/authentication.module';

import { APIModule } from '@api/api.module';

import { AuthenticationGuard, HTTPErrorHandlerProvider, AccessTokenAppendToHeaderProvider, ManageHttpProvider } from '@core/helpers';

const appRoutes: Routes = [
	{
		path: '',
		loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
	},
	{
		path: 'pages',
		canActivate: [AuthenticationGuard],
		loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
	},
	{
		path: 'miscellaneous',
		loadChildren: () => import('./main/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)
	},
	{
		path: '**',
		redirectTo: '/miscellaneous/error' //Error 404 - Page not found
	}
];

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRoutes, {
			scrollPositionRestoration: 'enabled', // Add options right here
			relativeLinkResolution: 'legacy'
			/* enableTracing: true  => debug purpose only */
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),

		//NgBootstrap
		NgbModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			preventDuplicates: true,
			tapToDismiss: true,
			maxOpened: 3
		}),
		BlockUIModule.forRoot(),

		// Core modules
		CoreModule.forRoot(CORE_CONFIG),
		CoreCommonModule,
		CoreSidebarModule,
		CoreThemeCustomizerModule,

		// App modules
		LayoutModule,
		AuthenticationModule,

		// API module
		APIModule.forRoot(),
	],
	bootstrap: [AppComponent],
	providers: [
		AccessTokenAppendToHeaderProvider, /* HttpInterceptor : Appen Access Token in Header */
		HTTPErrorHandlerProvider, /* Error Handler */
		ManageHttpProvider, /* Cancel HTTP Request */
		/* {
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptchaSiteKey
		} */
	]
})
export class AppModule { }
