<div class="auth-wrapper auth-v2">
	<div class="auth-inner row m-0 pb-0" style="overflow-x: hidden;">

		<div class="global-rllipse d-block d-lg-none"></div>
		
		<!-- Left Section-->
		<div class="d-flex col-lg-5 align-items-center auth-bg px-2 p-lg-5 reset-password-form-section">
			<div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="reset-password-form-header">
					<h1>Reset Password</h1>
					<p>Your new password should be different from previously used passwords.</p>
				</div>

				<div class="middle-container">
					<article class="reset-password-requirement mb-1" style="padding-left: 1rem;">
						<div class="row password-requirement">
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': minlengthValid}">
									at least 8 charaters
								</div>
							</div>
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': capitalValid}">
									one uppercase letter
								</div>
							</div>
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': digitValid}">
									one number
								</div>
							</div>
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': specialValid}">
									one special charater
								</div>
							</div>
						</div>
					</article>
				</div>

				<!-- Reset Password Form -->
				<div class="middle-container">
					<form class="auth-reset-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
						<div class="form-group">

							<!-- Label -->
							<div class="d-flex justify-content-between">
								<label for="reset-password-new">New Password</label>
							</div>

							<!-- Input group -->
							<div class="input-group input-group-merge form-password-toggle">

								<!-- Input -->
								<input [type]="passwordTextType ? 'text' : 'password'" formControlName="newPassword"
									class="form-control form-control-merge" autocomplete="off"
									[ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }"
									placeholder="············" aria-describedby="reset-password-new" />

								<!-- Input append-->
								<div class="input-group-append">
									<span class="input-group-text cursor-pointer">
										<i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }" (click)="togglePasswordTextType('new')"></i>
									</span>
								</div>
							</div>

						</div>

						<!-- Confirm Password -->
						<div class="form-group">

							<!-- Label -->
							<div class="d-flex justify-content-between">
								<label for="reset-password-confirm">Confirm Password</label>
							</div>

							<!-- Input group -->
							<div class="input-group input-group-merge form-password-toggle">

								<!-- Input -->
								<input [type]="confPasswordTextType ? 'text' : 'password'"
									formControlName="confirmPassword" class="form-control form-control-merge"
									autocomplete="off"
									[ngClass]="{ 'is-invalid error': submitted && (f.confirmPassword.errors || resetPasswordForm.errors) }"
									placeholder="············" aria-describedby="reset-password-confirm" />

								<!-- Input append-->
								<div class="input-group-append">
									<span class="input-group-text cursor-pointer">
										<i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': confPasswordTextType,
                      'icon-eye': !confPasswordTextType
                    }" (click)="togglePasswordTextType('cof')"></i>
									</span>
								</div>
							</div>

							<!-- Error feedback - control -->
							<div *ngIf="submitted && resetPasswordForm.errors"
								[ngClass]="{ 'd-block': submitted && resetPasswordForm.errors }"
								class="invalid-feedback">
								<div *ngIf="resetPasswordForm.errors.Mismatch">Confrim Password must be match</div>
							</div>

						</div>

						<!-- Term of Use Check -->
						<div class="form-group">
							<div class="custom-control custom-checkbox">
								<input type="checkbox"
									[ngClass]="{ 'is-invalid error': submitted && f.termOfUse.errors}"
									class="custom-control-input" id="Term_of_Use_Check" formControlName="termOfUse" />
								<label class="custom-control-label" for="Term_of_Use_Check">
									I agree to the <a class="text-primary" (click)="displayTermsOfUse($event)"
										style="text-decoration: underline;">Terms & conditions</a>
								</label>
							</div>
						</div>

						<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block" rippleEffect>
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Set New Password
						</button>
					</form>
				</div>

				<!-- Back to login -->
				<p class="text-center mt-2">
					<a routerLink="/login" id="back-to-login">
						<i data-feather="chevron-left" class="mb-25"></i> Back to login
					</a>
				</p>

			</div>
		</div>

		<!-- Right Section -->
		<div class="d-none d-lg-flex col-lg-7 reset-password-illustration-section">
			<div class="w-100 reset-password-illustration-image">
				<div class="rllipse"></div>
				<img src="assets/images/pages/reset-password-v2-dark.svg" alt="Reset password" />
			</div>
		</div>
	</div>
</div>