import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * BEC MAC Validator
 * 
 * @returns {ValidatorFn}
 */
export function becMacValidator(): ValidatorFn {
    const macFormat = /^(0004ED[0-9A-F]{6}|600347[0-9A-F]{6})(,(0004ED[0-9A-F]{6}|600347[0-9A-F]{6}))*$/;
    return (control: AbstractControl): ValidationErrors | null => {
        const format = macFormat.test(control.value.toUpperCase());
        return format ? null : { becMacFormat: true };
    };
}