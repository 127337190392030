import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalUIBlockingService {

    // Public
    public currentStatus$: Observable<boolean>;

    // Private blockUiStack
    private blockUiStack: boolean[] = []; // block ui stack    
    private currentStatusSubject: BehaviorSubject<boolean>; // curent Status BehaviorSubject
    private _loadingText: string = "Loading";

    /**
     * Constructor
     */
    constructor() {

        // Initialize Block Stack
        this.blockUiStack = [];

        // Blocking Status initial
        this.currentStatusSubject = new BehaviorSubject<boolean>(false);
        this.currentStatus$ = this.currentStatusSubject.asObservable();
    }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * Current Block UI Status
     */
    get currentBlockUIStatus(): boolean {
        return this.currentStatusSubject.value;
    }

    /**
     * get loading text value
     */
    get loadingTextValue(): string {
        return this._loadingText;
    }

    /**
     * set loding Text
     */
    set loadingText(text: string) {
        this._loadingText = text;
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the blocking screen
     * 
     * @param {string} text
     */
    public start(text: string = "Loading"): void {

        this._loadingText = text;

        if (this.blockUiStack.length == 0)
            this.currentStatusSubject.next(true);

        this.blockUiStack.push(true);
    }

    /**
     * Hide the blocking screen
     */
    public stop(): void {
        if (this.blockUiStack.length == 0) return;
        this.blockUiStack.pop();
        if (this.blockUiStack.length == 0)
            this.currentStatusSubject.next(false);
    }

    /**
     * enforce to stop all the blocking
     */
    public enforceToStop(): void {
        this.blockUiStack = [];
        this.currentStatusSubject.next(false);
    }
}
