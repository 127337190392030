export interface TimeZone {
    name: string;
    value: string;
};

export const TIME_ZONE_LIST: Array<TimeZone> = [
    {
       "value":"Pacific/Midway",
       "name":"(GMT-11:00) Pacific/Midway"
    },
    {
       "value":"Pacific/Niue",
       "name":"(GMT-11:00) Pacific/Niue"
    },
    {
       "value":"Pacific/Pago_Pago",
       "name":"(GMT-11:00) Pacific/Pago_Pago"
    },
    {
       "value":"America/Adak",
       "name":"(GMT-10:00) America/Adak"
    },
    {
       "value":"Pacific/Honolulu",
       "name":"(GMT-10:00) Pacific/Honolulu"
    },
    {
       "value":"Pacific/Rarotonga",
       "name":"(GMT-10:00) Pacific/Rarotonga"
    },
    {
       "value":"Pacific/Tahiti",
       "name":"(GMT-10:00) Pacific/Tahiti"
    },
    {
       "value":"Pacific/Marquesas",
       "name":"(GMT-09:30) Pacific/Marquesas"
    },
    {
       "value":"America/Anchorage",
       "name":"(GMT-09:00) America/Anchorage"
    },
    {
       "value":"America/Juneau",
       "name":"(GMT-09:00) America/Juneau"
    },
    {
       "value":"America/Metlakatla",
       "name":"(GMT-09:00) America/Metlakatla"
    },
    {
       "value":"America/Nome",
       "name":"(GMT-09:00) America/Nome"
    },
    {
       "value":"America/Sitka",
       "name":"(GMT-09:00) America/Sitka"
    },
    {
       "value":"America/Yakutat",
       "name":"(GMT-09:00) America/Yakutat"
    },
    {
       "value":"Pacific/Gambier",
       "name":"(GMT-09:00) Pacific/Gambier"
    },
    {
       "value":"America/Los_Angeles",
       "name":"(GMT-08:00) America/Los_Angeles"
    },
    {
       "value":"America/Tijuana",
       "name":"(GMT-08:00) America/Tijuana"
    },
    {
       "value":"America/Vancouver",
       "name":"(GMT-08:00) America/Vancouver"
    },
    {
       "value":"Pacific/Pitcairn",
       "name":"(GMT-08:00) Pacific/Pitcairn"
    },
    {
       "value":"America/Boise",
       "name":"(GMT-07:00) America/Boise"
    },
    {
       "value":"America/Cambridge_Bay",
       "name":"(GMT-07:00) America/Cambridge_Bay"
    },
    {
       "value":"America/Creston",
       "name":"(GMT-07:00) America/Creston"
    },
    {
       "value":"America/Dawson",
       "name":"(GMT-07:00) America/Dawson"
    },
    {
       "value":"America/Dawson_Creek",
       "name":"(GMT-07:00) America/Dawson_Creek"
    },
    {
       "value":"America/Denver",
       "name":"(GMT-07:00) America/Denver"
    },
    {
       "value":"America/Edmonton",
       "name":"(GMT-07:00) America/Edmonton"
    },
    {
       "value":"America/Fort_Nelson",
       "name":"(GMT-07:00) America/Fort_Nelson"
    },
    {
       "value":"America/Hermosillo",
       "name":"(GMT-07:00) America/Hermosillo"
    },
    {
       "value":"America/Inuvik",
       "name":"(GMT-07:00) America/Inuvik"
    },
    {
       "value":"America/Mazatlan",
       "name":"(GMT-07:00) America/Mazatlan"
    },
    {
       "value":"America/Phoenix",
       "name":"(GMT-07:00) America/Phoenix"
    },
    {
       "value":"America/Whitehorse",
       "name":"(GMT-07:00) America/Whitehorse"
    },
    {
       "value":"America/Yellowknife",
       "name":"(GMT-07:00) America/Yellowknife"
    },
    {
       "value":"America/Bahia_Banderas",
       "name":"(GMT-06:00) America/Bahia_Banderas"
    },
    {
       "value":"America/Belize",
       "name":"(GMT-06:00) America/Belize"
    },
    {
       "value":"America/Chicago",
       "name":"(GMT-06:00) America/Chicago"
    },
    {
       "value":"America/Chihuahua",
       "name":"(GMT-06:00) America/Chihuahua"
    },
    {
       "value":"America/Costa_Rica",
       "name":"(GMT-06:00) America/Costa_Rica"
    },
    {
       "value":"America/El_Salvador",
       "name":"(GMT-06:00) America/El_Salvador"
    },
    {
       "value":"America/Guatemala",
       "name":"(GMT-06:00) America/Guatemala"
    },
    {
       "value":"America/Managua",
       "name":"(GMT-06:00) America/Managua"
    },
    {
       "value":"America/Matamoros",
       "name":"(GMT-06:00) America/Matamoros"
    },
    {
       "value":"America/Menominee",
       "name":"(GMT-06:00) America/Menominee"
    },
    {
       "value":"America/Merida",
       "name":"(GMT-06:00) America/Merida"
    },
    {
       "value":"America/Mexico_City",
       "name":"(GMT-06:00) America/Mexico_City"
    },
    {
       "value":"America/Monterrey",
       "name":"(GMT-06:00) America/Monterrey"
    },
    {
       "value":"America/Ojinaga",
       "name":"(GMT-06:00) America/Ojinaga"
    },
    {
       "value":"America/Rainy_River",
       "name":"(GMT-06:00) America/Rainy_River"
    },
    {
       "value":"America/Rankin_Inlet",
       "name":"(GMT-06:00) America/Rankin_Inlet"
    },
    {
       "value":"America/Regina",
       "name":"(GMT-06:00) America/Regina"
    },
    {
       "value":"America/Resolute",
       "name":"(GMT-06:00) America/Resolute"
    },
    {
       "value":"America/Swift_Current",
       "name":"(GMT-06:00) America/Swift_Current"
    },
    {
       "value":"America/Tegucigalpa",
       "name":"(GMT-06:00) America/Tegucigalpa"
    },
    {
       "value":"America/Winnipeg",
       "name":"(GMT-06:00) America/Winnipeg"
    },
    {
       "value":"Pacific/Galapagos",
       "name":"(GMT-06:00) Pacific/Galapagos"
    },
    {
       "value":"America/Atikokan",
       "name":"(GMT-05:00) America/Atikokan"
    },
    {
       "value":"America/Bogota",
       "name":"(GMT-05:00) America/Bogota"
    },
    {
       "value":"America/Cancun",
       "name":"(GMT-05:00) America/Cancun"
    },
    {
       "value":"America/Cayman",
       "name":"(GMT-05:00) America/Cayman"
    },
    {
       "value":"America/Detroit",
       "name":"(GMT-05:00) America/Detroit"
    },
    {
       "value":"America/Eirunepe",
       "name":"(GMT-05:00) America/Eirunepe"
    },
    {
       "value":"America/Grand_Turk",
       "name":"(GMT-05:00) America/Grand_Turk"
    },
    {
       "value":"America/Guayaquil",
       "name":"(GMT-05:00) America/Guayaquil"
    },
    {
       "value":"America/Havana",
       "name":"(GMT-05:00) America/Havana"
    },
    {
       "value":"America/Iqaluit",
       "name":"(GMT-05:00) America/Iqaluit"
    },
    {
       "value":"America/Jamaica",
       "name":"(GMT-05:00) America/Jamaica"
    },
    {
       "value":"America/Lima",
       "name":"(GMT-05:00) America/Lima"
    },
    {
       "value":"America/Nassau",
       "name":"(GMT-05:00) America/Nassau"
    },
    {
       "value":"America/New_York",
       "name":"(GMT-05:00) America/New_York"
    },
    {
       "value":"America/Nipigon",
       "name":"(GMT-05:00) America/Nipigon"
    },
    {
       "value":"America/Panama",
       "name":"(GMT-05:00) America/Panama"
    },
    {
       "value":"America/Pangnirtung",
       "name":"(GMT-05:00) America/Pangnirtung"
    },
    {
       "value":"America/Port-au-Prince",
       "name":"(GMT-05:00) America/Port-au-Prince"
    },
    {
       "value":"America/Rio_Branco",
       "name":"(GMT-05:00) America/Rio_Branco"
    },
    {
       "value":"America/Thunder_Bay",
       "name":"(GMT-05:00) America/Thunder_Bay"
    },
    {
       "value":"America/Toronto",
       "name":"(GMT-05:00) America/Toronto"
    },
    {
       "value":"Pacific/Easter",
       "name":"(GMT-05:00) Pacific/Easter"
    },
    {
       "value":"America/Anguilla",
       "name":"(GMT-04:00) America/Anguilla"
    },
    {
       "value":"America/Antigua",
       "name":"(GMT-04:00) America/Antigua"
    },
    {
       "value":"America/Aruba",
       "name":"(GMT-04:00) America/Aruba"
    },
    {
       "value":"America/Barbados",
       "name":"(GMT-04:00) America/Barbados"
    },
    {
       "value":"America/Blanc-Sablon",
       "name":"(GMT-04:00) America/Blanc-Sablon"
    },
    {
       "value":"America/Boa_Vista",
       "name":"(GMT-04:00) America/Boa_Vista"
    },
    {
       "value":"America/Campo_Grande",
       "name":"(GMT-04:00) America/Campo_Grande"
    },
    {
       "value":"America/Caracas",
       "name":"(GMT-04:00) America/Caracas"
    },
    {
       "value":"America/Cuiaba",
       "name":"(GMT-04:00) America/Cuiaba"
    },
    {
       "value":"America/Curacao",
       "name":"(GMT-04:00) America/Curacao"
    },
    {
       "value":"America/Dominica",
       "name":"(GMT-04:00) America/Dominica"
    },
    {
       "value":"America/Glace_Bay",
       "name":"(GMT-04:00) America/Glace_Bay"
    },
    {
       "value":"America/Goose_Bay",
       "name":"(GMT-04:00) America/Goose_Bay"
    },
    {
       "value":"America/Grenada",
       "name":"(GMT-04:00) America/Grenada"
    },
    {
       "value":"America/Guadeloupe",
       "name":"(GMT-04:00) America/Guadeloupe"
    },
    {
       "value":"America/Guyana",
       "name":"(GMT-04:00) America/Guyana"
    },
    {
       "value":"America/Halifax",
       "name":"(GMT-04:00) America/Halifax"
    },
    {
       "value":"America/Kralendijk",
       "name":"(GMT-04:00) America/Kralendijk"
    },
    {
       "value":"America/La_Paz",
       "name":"(GMT-04:00) America/La_Paz"
    },
    {
       "value":"America/Lower_Princes",
       "name":"(GMT-04:00) America/Lower_Princes"
    },
    {
       "value":"America/Manaus",
       "name":"(GMT-04:00) America/Manaus"
    },
    {
       "value":"America/Marigot",
       "name":"(GMT-04:00) America/Marigot"
    },
    {
       "value":"America/Martinique",
       "name":"(GMT-04:00) America/Martinique"
    },
    {
       "value":"America/Moncton",
       "name":"(GMT-04:00) America/Moncton"
    },
    {
       "value":"America/Montserrat",
       "name":"(GMT-04:00) America/Montserrat"
    },
    {
       "value":"America/Port_of_Spain",
       "name":"(GMT-04:00) America/Port_of_Spain"
    },
    {
       "value":"America/Porto_Velho",
       "name":"(GMT-04:00) America/Porto_Velho"
    },
    {
       "value":"America/Puerto_Rico",
       "name":"(GMT-04:00) America/Puerto_Rico"
    },
    {
       "value":"America/Santo_Domingo",
       "name":"(GMT-04:00) America/Santo_Domingo"
    },
    {
       "value":"America/St_Barthelemy",
       "name":"(GMT-04:00) America/St_Barthelemy"
    },
    {
       "value":"America/St_Kitts",
       "name":"(GMT-04:00) America/St_Kitts"
    },
    {
       "value":"America/St_Lucia",
       "name":"(GMT-04:00) America/St_Lucia"
    },
    {
       "value":"America/St_Thomas",
       "name":"(GMT-04:00) America/St_Thomas"
    },
    {
       "value":"America/St_Vincent",
       "name":"(GMT-04:00) America/St_Vincent"
    },
    {
       "value":"America/Thule",
       "name":"(GMT-04:00) America/Thule"
    },
    {
       "value":"America/Tortola",
       "name":"(GMT-04:00) America/Tortola"
    },
    {
       "value":"Atlantic/Bermuda",
       "name":"(GMT-04:00) Atlantic/Bermuda"
    },
    {
       "value":"America/St_Johns",
       "name":"(GMT-03:30) America/St_Johns"
    },
    {
       "value":"America/Araguaina",
       "name":"(GMT-03:00) America/Araguaina"
    },
    {
       "value":"America/Asuncion",
       "name":"(GMT-03:00) America/Asuncion"
    },
    {
       "value":"America/Bahia",
       "name":"(GMT-03:00) America/Bahia"
    },
    {
       "value":"America/Belem",
       "name":"(GMT-03:00) America/Belem"
    },
    {
       "value":"America/Cayenne",
       "name":"(GMT-03:00) America/Cayenne"
    },
    {
       "value":"America/Fortaleza",
       "name":"(GMT-03:00) America/Fortaleza"
    },
    {
       "value":"America/Maceio",
       "name":"(GMT-03:00) America/Maceio"
    },
    {
       "value":"America/Miquelon",
       "name":"(GMT-03:00) America/Miquelon"
    },
    {
       "value":"America/Montevideo",
       "name":"(GMT-03:00) America/Montevideo"
    },
    {
       "value":"America/Nuuk",
       "name":"(GMT-03:00) America/Nuuk"
    },
    {
       "value":"America/Paramaribo",
       "name":"(GMT-03:00) America/Paramaribo"
    },
    {
       "value":"America/Punta_Arenas",
       "name":"(GMT-03:00) America/Punta_Arenas"
    },
    {
       "value":"America/Recife",
       "name":"(GMT-03:00) America/Recife"
    },
    {
       "value":"America/Santarem",
       "name":"(GMT-03:00) America/Santarem"
    },
    {
       "value":"America/Santiago",
       "name":"(GMT-03:00) America/Santiago"
    },
    {
       "value":"America/Sao_Paulo",
       "name":"(GMT-03:00) America/Sao_Paulo"
    },
    {
       "value":"Antarctica/Palmer",
       "name":"(GMT-03:00) Antarctica/Palmer"
    },
    {
       "value":"Antarctica/Rothera",
       "name":"(GMT-03:00) Antarctica/Rothera"
    },
    {
       "value":"Atlantic/Stanley",
       "name":"(GMT-03:00) Atlantic/Stanley"
    },
    {
       "value":"America/Noronha",
       "name":"(GMT-02:00) America/Noronha"
    },
    {
       "value":"Atlantic/South_Georgia",
       "name":"(GMT-02:00) Atlantic/South_Georgia"
    },
    {
       "value":"America/Scoresbysund",
       "name":"(GMT-01:00) America/Scoresbysund"
    },
    {
       "value":"Atlantic/Azores",
       "name":"(GMT-01:00) Atlantic/Azores"
    },
    {
       "value":"Atlantic/Cape_Verde",
       "name":"(GMT-01:00) Atlantic/Cape_Verde"
    },
    {
       "value":"Africa/Abidjan",
       "name":"(GMT+00:00) Africa/Abidjan"
    },
    {
       "value":"Africa/Accra",
       "name":"(GMT+00:00) Africa/Accra"
    },
    {
       "value":"Africa/Bamako",
       "name":"(GMT+00:00) Africa/Bamako"
    },
    {
       "value":"Africa/Banjul",
       "name":"(GMT+00:00) Africa/Banjul"
    },
    {
       "value":"Africa/Bissau",
       "name":"(GMT+00:00) Africa/Bissau"
    },
    {
       "value":"Africa/Conakry",
       "name":"(GMT+00:00) Africa/Conakry"
    },
    {
       "value":"Africa/Dakar",
       "name":"(GMT+00:00) Africa/Dakar"
    },
    {
       "value":"Africa/Freetown",
       "name":"(GMT+00:00) Africa/Freetown"
    },
    {
       "value":"Africa/Lome",
       "name":"(GMT+00:00) Africa/Lome"
    },
    {
       "value":"Africa/Monrovia",
       "name":"(GMT+00:00) Africa/Monrovia"
    },
    {
       "value":"Africa/Nouakchott",
       "name":"(GMT+00:00) Africa/Nouakchott"
    },
    {
       "value":"Africa/Ouagadougou",
       "name":"(GMT+00:00) Africa/Ouagadougou"
    },
    {
       "value":"Africa/Sao_Tome",
       "name":"(GMT+00:00) Africa/Sao_Tome"
    },
    {
       "value":"America/Danmarkshavn",
       "name":"(GMT+00:00) America/Danmarkshavn"
    },
    {
       "value":"Antarctica/Troll",
       "name":"(GMT+00:00) Antarctica/Troll"
    },
    {
       "value":"Atlantic/Canary",
       "name":"(GMT+00:00) Atlantic/Canary"
    },
    {
       "value":"Atlantic/Faroe",
       "name":"(GMT+00:00) Atlantic/Faroe"
    },
    {
       "value":"Atlantic/Madeira",
       "name":"(GMT+00:00) Atlantic/Madeira"
    },
    {
       "value":"Atlantic/Reykjavik",
       "name":"(GMT+00:00) Atlantic/Reykjavik"
    },
    {
       "value":"Atlantic/St_Helena",
       "name":"(GMT+00:00) Atlantic/St_Helena"
    },
    {
       "value":"Europe/Dublin",
       "name":"(GMT+00:00) Europe/Dublin"
    },
    {
       "value":"Europe/Guernsey",
       "name":"(GMT+00:00) Europe/Guernsey"
    },
    {
       "value":"Europe/Isle_of_Man",
       "name":"(GMT+00:00) Europe/Isle_of_Man"
    },
    {
       "value":"Europe/Jersey",
       "name":"(GMT+00:00) Europe/Jersey"
    },
    {
       "value":"Europe/Lisbon",
       "name":"(GMT+00:00) Europe/Lisbon"
    },
    {
       "value":"Europe/London",
       "name":"(GMT+00:00) Europe/London"
    },
    {
       "value":"Africa/Algiers",
       "name":"(GMT+01:00) Africa/Algiers"
    },
    {
       "value":"Africa/Bangui",
       "name":"(GMT+01:00) Africa/Bangui"
    },
    {
       "value":"Africa/Brazzaville",
       "name":"(GMT+01:00) Africa/Brazzaville"
    },
    {
       "value":"Africa/Casablanca",
       "name":"(GMT+01:00) Africa/Casablanca"
    },
    {
       "value":"Africa/Ceuta",
       "name":"(GMT+01:00) Africa/Ceuta"
    },
    {
       "value":"Africa/Douala",
       "name":"(GMT+01:00) Africa/Douala"
    },
    {
       "value":"Africa/El_Aaiun",
       "name":"(GMT+01:00) Africa/El_Aaiun"
    },
    {
       "value":"Africa/Kinshasa",
       "name":"(GMT+01:00) Africa/Kinshasa"
    },
    {
       "value":"Africa/Lagos",
       "name":"(GMT+01:00) Africa/Lagos"
    },
    {
       "value":"Africa/Libreville",
       "name":"(GMT+01:00) Africa/Libreville"
    },
    {
       "value":"Africa/Luanda",
       "name":"(GMT+01:00) Africa/Luanda"
    },
    {
       "value":"Africa/Malabo",
       "name":"(GMT+01:00) Africa/Malabo"
    },
    {
       "value":"Africa/Ndjamena",
       "name":"(GMT+01:00) Africa/Ndjamena"
    },
    {
       "value":"Africa/Niamey",
       "name":"(GMT+01:00) Africa/Niamey"
    },
    {
       "value":"Africa/Porto-Novo",
       "name":"(GMT+01:00) Africa/Porto-Novo"
    },
    {
       "value":"Africa/Tunis",
       "name":"(GMT+01:00) Africa/Tunis"
    },
    {
       "value":"Arctic/Longyearbyen",
       "name":"(GMT+01:00) Arctic/Longyearbyen"
    },
    {
       "value":"Europe/Amsterdam",
       "name":"(GMT+01:00) Europe/Amsterdam"
    },
    {
       "value":"Europe/Andorra",
       "name":"(GMT+01:00) Europe/Andorra"
    },
    {
       "value":"Europe/Belgrade",
       "name":"(GMT+01:00) Europe/Belgrade"
    },
    {
       "value":"Europe/Berlin",
       "name":"(GMT+01:00) Europe/Berlin"
    },
    {
       "value":"Europe/Bratislava",
       "name":"(GMT+01:00) Europe/Bratislava"
    },
    {
       "value":"Europe/Brussels",
       "name":"(GMT+01:00) Europe/Brussels"
    },
    {
       "value":"Europe/Budapest",
       "name":"(GMT+01:00) Europe/Budapest"
    },
    {
       "value":"Europe/Busingen",
       "name":"(GMT+01:00) Europe/Busingen"
    },
    {
       "value":"Europe/Copenhagen",
       "name":"(GMT+01:00) Europe/Copenhagen"
    },
    {
       "value":"Europe/Gibraltar",
       "name":"(GMT+01:00) Europe/Gibraltar"
    },
    {
       "value":"Europe/Ljubljana",
       "name":"(GMT+01:00) Europe/Ljubljana"
    },
    {
       "value":"Europe/Luxembourg",
       "name":"(GMT+01:00) Europe/Luxembourg"
    },
    {
       "value":"Europe/Madrid",
       "name":"(GMT+01:00) Europe/Madrid"
    },
    {
       "value":"Europe/Malta",
       "name":"(GMT+01:00) Europe/Malta"
    },
    {
       "value":"Europe/Monaco",
       "name":"(GMT+01:00) Europe/Monaco"
    },
    {
       "value":"Europe/Oslo",
       "name":"(GMT+01:00) Europe/Oslo"
    },
    {
       "value":"Europe/Paris",
       "name":"(GMT+01:00) Europe/Paris"
    },
    {
       "value":"Europe/Podgorica",
       "name":"(GMT+01:00) Europe/Podgorica"
    },
    {
       "value":"Europe/Prague",
       "name":"(GMT+01:00) Europe/Prague"
    },
    {
       "value":"Europe/Rome",
       "name":"(GMT+01:00) Europe/Rome"
    },
    {
       "value":"Europe/San_Marino",
       "name":"(GMT+01:00) Europe/San_Marino"
    },
    {
       "value":"Europe/Sarajevo",
       "name":"(GMT+01:00) Europe/Sarajevo"
    },
    {
       "value":"Europe/Skopje",
       "name":"(GMT+01:00) Europe/Skopje"
    },
    {
       "value":"Europe/Stockholm",
       "name":"(GMT+01:00) Europe/Stockholm"
    },
    {
       "value":"Europe/Tirane",
       "name":"(GMT+01:00) Europe/Tirane"
    },
    {
       "value":"Europe/Vaduz",
       "name":"(GMT+01:00) Europe/Vaduz"
    },
    {
       "value":"Europe/Vatican",
       "name":"(GMT+01:00) Europe/Vatican"
    },
    {
       "value":"Europe/Vienna",
       "name":"(GMT+01:00) Europe/Vienna"
    },
    {
       "value":"Europe/Warsaw",
       "name":"(GMT+01:00) Europe/Warsaw"
    },
    {
       "value":"Europe/Zagreb",
       "name":"(GMT+01:00) Europe/Zagreb"
    },
    {
       "value":"Europe/Zurich",
       "name":"(GMT+01:00) Europe/Zurich"
    },
    {
       "value":"Africa/Blantyre",
       "name":"(GMT+02:00) Africa/Blantyre"
    },
    {
       "value":"Africa/Bujumbura",
       "name":"(GMT+02:00) Africa/Bujumbura"
    },
    {
       "value":"Africa/Cairo",
       "name":"(GMT+02:00) Africa/Cairo"
    },
    {
       "value":"Africa/Gaborone",
       "name":"(GMT+02:00) Africa/Gaborone"
    },
    {
       "value":"Africa/Harare",
       "name":"(GMT+02:00) Africa/Harare"
    },
    {
       "value":"Africa/Johannesburg",
       "name":"(GMT+02:00) Africa/Johannesburg"
    },
    {
       "value":"Africa/Juba",
       "name":"(GMT+02:00) Africa/Juba"
    },
    {
       "value":"Africa/Khartoum",
       "name":"(GMT+02:00) Africa/Khartoum"
    },
    {
       "value":"Africa/Kigali",
       "name":"(GMT+02:00) Africa/Kigali"
    },
    {
       "value":"Africa/Lubumbashi",
       "name":"(GMT+02:00) Africa/Lubumbashi"
    },
    {
       "value":"Africa/Lusaka",
       "name":"(GMT+02:00) Africa/Lusaka"
    },
    {
       "value":"Africa/Maputo",
       "name":"(GMT+02:00) Africa/Maputo"
    },
    {
       "value":"Africa/Maseru",
       "name":"(GMT+02:00) Africa/Maseru"
    },
    {
       "value":"Africa/Mbabane",
       "name":"(GMT+02:00) Africa/Mbabane"
    },
    {
       "value":"Africa/Tripoli",
       "name":"(GMT+02:00) Africa/Tripoli"
    },
    {
       "value":"Africa/Windhoek",
       "name":"(GMT+02:00) Africa/Windhoek"
    },
    {
       "value":"Asia/Beirut",
       "name":"(GMT+02:00) Asia/Beirut"
    },
    {
       "value":"Asia/Famagusta",
       "name":"(GMT+02:00) Asia/Famagusta"
    },
    {
       "value":"Asia/Gaza",
       "name":"(GMT+02:00) Asia/Gaza"
    },
    {
       "value":"Asia/Hebron",
       "name":"(GMT+02:00) Asia/Hebron"
    },
    {
       "value":"Asia/Jerusalem",
       "name":"(GMT+02:00) Asia/Jerusalem"
    },
    {
       "value":"Asia/Nicosia",
       "name":"(GMT+02:00) Asia/Nicosia"
    },
    {
       "value":"Europe/Athens",
       "name":"(GMT+02:00) Europe/Athens"
    },
    {
       "value":"Europe/Bucharest",
       "name":"(GMT+02:00) Europe/Bucharest"
    },
    {
       "value":"Europe/Chisinau",
       "name":"(GMT+02:00) Europe/Chisinau"
    },
    {
       "value":"Europe/Helsinki",
       "name":"(GMT+02:00) Europe/Helsinki"
    },
    {
       "value":"Europe/Kaliningrad",
       "name":"(GMT+02:00) Europe/Kaliningrad"
    },
    {
       "value":"Europe/Kiev",
       "name":"(GMT+02:00) Europe/Kiev"
    },
    {
       "value":"Europe/Mariehamn",
       "name":"(GMT+02:00) Europe/Mariehamn"
    },
    {
       "value":"Europe/Riga",
       "name":"(GMT+02:00) Europe/Riga"
    },
    {
       "value":"Europe/Sofia",
       "name":"(GMT+02:00) Europe/Sofia"
    },
    {
       "value":"Europe/Tallinn",
       "name":"(GMT+02:00) Europe/Tallinn"
    },
    {
       "value":"Europe/Uzhgorod",
       "name":"(GMT+02:00) Europe/Uzhgorod"
    },
    {
       "value":"Europe/Vilnius",
       "name":"(GMT+02:00) Europe/Vilnius"
    },
    {
       "value":"Europe/Zaporozhye",
       "name":"(GMT+02:00) Europe/Zaporozhye"
    },
    {
       "value":"Africa/Addis_Ababa",
       "name":"(GMT+03:00) Africa/Addis_Ababa"
    },
    {
       "value":"Africa/Asmara",
       "name":"(GMT+03:00) Africa/Asmara"
    },
    {
       "value":"Africa/Dar_es_Salaam",
       "name":"(GMT+03:00) Africa/Dar_es_Salaam"
    },
    {
       "value":"Africa/Djibouti",
       "name":"(GMT+03:00) Africa/Djibouti"
    },
    {
       "value":"Africa/Kampala",
       "name":"(GMT+03:00) Africa/Kampala"
    },
    {
       "value":"Africa/Mogadishu",
       "name":"(GMT+03:00) Africa/Mogadishu"
    },
    {
       "value":"Africa/Nairobi",
       "name":"(GMT+03:00) Africa/Nairobi"
    },
    {
       "value":"Antarctica/Syowa",
       "name":"(GMT+03:00) Antarctica/Syowa"
    },
    {
       "value":"Asia/Aden",
       "name":"(GMT+03:00) Asia/Aden"
    },
    {
       "value":"Asia/Amman",
       "name":"(GMT+03:00) Asia/Amman"
    },
    {
       "value":"Asia/Baghdad",
       "name":"(GMT+03:00) Asia/Baghdad"
    },
    {
       "value":"Asia/Bahrain",
       "name":"(GMT+03:00) Asia/Bahrain"
    },
    {
       "value":"Asia/Damascus",
       "name":"(GMT+03:00) Asia/Damascus"
    },
    {
       "value":"Asia/Kuwait",
       "name":"(GMT+03:00) Asia/Kuwait"
    },
    {
       "value":"Asia/Qatar",
       "name":"(GMT+03:00) Asia/Qatar"
    },
    {
       "value":"Asia/Riyadh",
       "name":"(GMT+03:00) Asia/Riyadh"
    },
    {
       "value":"Europe/Istanbul",
       "name":"(GMT+03:00) Europe/Istanbul"
    },
    {
       "value":"Europe/Kirov",
       "name":"(GMT+03:00) Europe/Kirov"
    },
    {
       "value":"Europe/Minsk",
       "name":"(GMT+03:00) Europe/Minsk"
    },
    {
       "value":"Europe/Moscow",
       "name":"(GMT+03:00) Europe/Moscow"
    },
    {
       "value":"Europe/Simferopol",
       "name":"(GMT+03:00) Europe/Simferopol"
    },
    {
       "value":"Europe/Volgograd",
       "name":"(GMT+03:00) Europe/Volgograd"
    },
    {
       "value":"Indian/Antananarivo",
       "name":"(GMT+03:00) Indian/Antananarivo"
    },
    {
       "value":"Indian/Comoro",
       "name":"(GMT+03:00) Indian/Comoro"
    },
    {
       "value":"Indian/Mayotte",
       "name":"(GMT+03:00) Indian/Mayotte"
    },
    {
       "value":"Asia/Tehran",
       "name":"(GMT+03:30) Asia/Tehran"
    },
    {
       "value":"Asia/Baku",
       "name":"(GMT+04:00) Asia/Baku"
    },
    {
       "value":"Asia/Dubai",
       "name":"(GMT+04:00) Asia/Dubai"
    },
    {
       "value":"Asia/Muscat",
       "name":"(GMT+04:00) Asia/Muscat"
    },
    {
       "value":"Asia/Tbilisi",
       "name":"(GMT+04:00) Asia/Tbilisi"
    },
    {
       "value":"Asia/Yerevan",
       "name":"(GMT+04:00) Asia/Yerevan"
    },
    {
       "value":"Europe/Astrakhan",
       "name":"(GMT+04:00) Europe/Astrakhan"
    },
    {
       "value":"Europe/Samara",
       "name":"(GMT+04:00) Europe/Samara"
    },
    {
       "value":"Europe/Saratov",
       "name":"(GMT+04:00) Europe/Saratov"
    },
    {
       "value":"Europe/Ulyanovsk",
       "name":"(GMT+04:00) Europe/Ulyanovsk"
    },
    {
       "value":"Indian/Mahe",
       "name":"(GMT+04:00) Indian/Mahe"
    },
    {
       "value":"Indian/Mauritius",
       "name":"(GMT+04:00) Indian/Mauritius"
    },
    {
       "value":"Indian/Reunion",
       "name":"(GMT+04:00) Indian/Reunion"
    },
    {
       "value":"Asia/Kabul",
       "name":"(GMT+04:30) Asia/Kabul"
    },
    {
       "value":"Antarctica/Mawson",
       "name":"(GMT+05:00) Antarctica/Mawson"
    },
    {
       "value":"Asia/Aqtau",
       "name":"(GMT+05:00) Asia/Aqtau"
    },
    {
       "value":"Asia/Aqtobe",
       "name":"(GMT+05:00) Asia/Aqtobe"
    },
    {
       "value":"Asia/Ashgabat",
       "name":"(GMT+05:00) Asia/Ashgabat"
    },
    {
       "value":"Asia/Atyrau",
       "name":"(GMT+05:00) Asia/Atyrau"
    },
    {
       "value":"Asia/Dushanbe",
       "name":"(GMT+05:00) Asia/Dushanbe"
    },
    {
       "value":"Asia/Karachi",
       "name":"(GMT+05:00) Asia/Karachi"
    },
    {
       "value":"Asia/Oral",
       "name":"(GMT+05:00) Asia/Oral"
    },
    {
       "value":"Asia/Qyzylorda",
       "name":"(GMT+05:00) Asia/Qyzylorda"
    },
    {
       "value":"Asia/Samarkand",
       "name":"(GMT+05:00) Asia/Samarkand"
    },
    {
       "value":"Asia/Tashkent",
       "name":"(GMT+05:00) Asia/Tashkent"
    },
    {
       "value":"Asia/Yekaterinburg",
       "name":"(GMT+05:00) Asia/Yekaterinburg"
    },
    {
       "value":"Indian/Kerguelen",
       "name":"(GMT+05:00) Indian/Kerguelen"
    },
    {
       "value":"Indian/Maldives",
       "name":"(GMT+05:00) Indian/Maldives"
    },
    {
       "value":"Asia/Colombo",
       "name":"(GMT+05:30) Asia/Colombo"
    },
    {
       "value":"Asia/Kolkata",
       "name":"(GMT+05:30) Asia/Kolkata"
    },
    {
       "value":"Asia/Kathmandu",
       "name":"(GMT+05:45) Asia/Kathmandu"
    },
    {
       "value":"Antarctica/Vostok",
       "name":"(GMT+06:00) Antarctica/Vostok"
    },
    {
       "value":"Asia/Almaty",
       "name":"(GMT+06:00) Asia/Almaty"
    },
    {
       "value":"Asia/Bishkek",
       "name":"(GMT+06:00) Asia/Bishkek"
    },
    {
       "value":"Asia/Dhaka",
       "name":"(GMT+06:00) Asia/Dhaka"
    },
    {
       "value":"Asia/Omsk",
       "name":"(GMT+06:00) Asia/Omsk"
    },
    {
       "value":"Asia/Qostanay",
       "name":"(GMT+06:00) Asia/Qostanay"
    },
    {
       "value":"Asia/Thimphu",
       "name":"(GMT+06:00) Asia/Thimphu"
    },
    {
       "value":"Asia/Urumqi",
       "name":"(GMT+06:00) Asia/Urumqi"
    },
    {
       "value":"Indian/Chagos",
       "name":"(GMT+06:00) Indian/Chagos"
    },
    {
       "value":"Asia/Yangon",
       "name":"(GMT+06:30) Asia/Yangon"
    },
    {
       "value":"Indian/Cocos",
       "name":"(GMT+06:30) Indian/Cocos"
    },
    {
       "value":"Antarctica/Davis",
       "name":"(GMT+07:00) Antarctica/Davis"
    },
    {
       "value":"Asia/Bangkok",
       "name":"(GMT+07:00) Asia/Bangkok"
    },
    {
       "value":"Asia/Barnaul",
       "name":"(GMT+07:00) Asia/Barnaul"
    },
    {
       "value":"Asia/Ho_Chi_Minh",
       "name":"(GMT+07:00) Asia/Ho_Chi_Minh"
    },
    {
       "value":"Asia/Hovd",
       "name":"(GMT+07:00) Asia/Hovd"
    },
    {
       "value":"Asia/Jakarta",
       "name":"(GMT+07:00) Asia/Jakarta"
    },
    {
       "value":"Asia/Krasnoyarsk",
       "name":"(GMT+07:00) Asia/Krasnoyarsk"
    },
    {
       "value":"Asia/Novokuznetsk",
       "name":"(GMT+07:00) Asia/Novokuznetsk"
    },
    {
       "value":"Asia/Novosibirsk",
       "name":"(GMT+07:00) Asia/Novosibirsk"
    },
    {
       "value":"Asia/Phnom_Penh",
       "name":"(GMT+07:00) Asia/Phnom_Penh"
    },
    {
       "value":"Asia/Pontianak",
       "name":"(GMT+07:00) Asia/Pontianak"
    },
    {
       "value":"Asia/Tomsk",
       "name":"(GMT+07:00) Asia/Tomsk"
    },
    {
       "value":"Asia/Vientiane",
       "name":"(GMT+07:00) Asia/Vientiane"
    },
    {
       "value":"Indian/Christmas",
       "name":"(GMT+07:00) Indian/Christmas"
    },
    {
       "value":"Asia/Brunei",
       "name":"(GMT+08:00) Asia/Brunei"
    },
    {
       "value":"Asia/Choibalsan",
       "name":"(GMT+08:00) Asia/Choibalsan"
    },
    {
       "value":"Asia/Hong_Kong",
       "name":"(GMT+08:00) Asia/Hong_Kong"
    },
    {
       "value":"Asia/Irkutsk",
       "name":"(GMT+08:00) Asia/Irkutsk"
    },
    {
       "value":"Asia/Kuala_Lumpur",
       "name":"(GMT+08:00) Asia/Kuala_Lumpur"
    },
    {
       "value":"Asia/Kuching",
       "name":"(GMT+08:00) Asia/Kuching"
    },
    {
       "value":"Asia/Macau",
       "name":"(GMT+08:00) Asia/Macau"
    },
    {
       "value":"Asia/Makassar",
       "name":"(GMT+08:00) Asia/Makassar"
    },
    {
       "value":"Asia/Manila",
       "name":"(GMT+08:00) Asia/Manila"
    },
    {
       "value":"Asia/Shanghai",
       "name":"(GMT+08:00) Asia/Shanghai"
    },
    {
       "value":"Asia/Singapore",
       "name":"(GMT+08:00) Asia/Singapore"
    },
    {
       "value":"Asia/Taipei",
       "name":"(GMT+08:00) Asia/Taipei"
    },
    {
       "value":"Asia/Ulaanbaatar",
       "name":"(GMT+08:00) Asia/Ulaanbaatar"
    },
    {
       "value":"Australia/Perth",
       "name":"(GMT+08:00) Australia/Perth"
    },
    {
       "value":"Australia/Eucla",
       "name":"(GMT+08:45) Australia/Eucla"
    },
    {
       "value":"Asia/Chita",
       "name":"(GMT+09:00) Asia/Chita"
    },
    {
       "value":"Asia/Dili",
       "name":"(GMT+09:00) Asia/Dili"
    },
    {
       "value":"Asia/Jayapura",
       "name":"(GMT+09:00) Asia/Jayapura"
    },
    {
       "value":"Asia/Khandyga",
       "name":"(GMT+09:00) Asia/Khandyga"
    },
    {
       "value":"Asia/Pyongyang",
       "name":"(GMT+09:00) Asia/Pyongyang"
    },
    {
       "value":"Asia/Seoul",
       "name":"(GMT+09:00) Asia/Seoul"
    },
    {
       "value":"Asia/Tokyo",
       "name":"(GMT+09:00) Asia/Tokyo"
    },
    {
       "value":"Asia/Yakutsk",
       "name":"(GMT+09:00) Asia/Yakutsk"
    },
    {
       "value":"Pacific/Palau",
       "name":"(GMT+09:00) Pacific/Palau"
    },
    {
       "value":"Australia/Darwin",
       "name":"(GMT+09:30) Australia/Darwin"
    },
    {
       "value":"Antarctica/DumontDUrville",
       "name":"(GMT+10:00) Antarctica/DumontDUrville"
    },
    {
       "value":"Asia/Ust-Nera",
       "name":"(GMT+10:00) Asia/Ust-Nera"
    },
    {
       "value":"Asia/Vladivostok",
       "name":"(GMT+10:00) Asia/Vladivostok"
    },
    {
       "value":"Australia/Brisbane",
       "name":"(GMT+10:00) Australia/Brisbane"
    },
    {
       "value":"Australia/Lindeman",
       "name":"(GMT+10:00) Australia/Lindeman"
    },
    {
       "value":"Pacific/Chuuk",
       "name":"(GMT+10:00) Pacific/Chuuk"
    },
    {
       "value":"Pacific/Guam",
       "name":"(GMT+10:00) Pacific/Guam"
    },
    {
       "value":"Pacific/Port_Moresby",
       "name":"(GMT+10:00) Pacific/Port_Moresby"
    },
    {
       "value":"Pacific/Saipan",
       "name":"(GMT+10:00) Pacific/Saipan"
    },
    {
       "value":"Australia/Adelaide",
       "name":"(GMT+10:30) Australia/Adelaide"
    },
    {
       "value":"Australia/Broken_Hill",
       "name":"(GMT+10:30) Australia/Broken_Hill"
    },
    {
       "value":"Antarctica/Casey",
       "name":"(GMT+11:00) Antarctica/Casey"
    },
    {
       "value":"Antarctica/Macquarie",
       "name":"(GMT+11:00) Antarctica/Macquarie"
    },
    {
       "value":"Asia/Magadan",
       "name":"(GMT+11:00) Asia/Magadan"
    },
    {
       "value":"Asia/Sakhalin",
       "name":"(GMT+11:00) Asia/Sakhalin"
    },
    {
       "value":"Asia/Srednekolymsk",
       "name":"(GMT+11:00) Asia/Srednekolymsk"
    },
    {
       "value":"Australia/Hobart",
       "name":"(GMT+11:00) Australia/Hobart"
    },
    {
       "value":"Australia/Lord_Howe",
       "name":"(GMT+11:00) Australia/Lord_Howe"
    },
    {
       "value":"Australia/Melbourne",
       "name":"(GMT+11:00) Australia/Melbourne"
    },
    {
       "value":"Australia/Sydney",
       "name":"(GMT+11:00) Australia/Sydney"
    },
    {
       "value":"Pacific/Bougainville",
       "name":"(GMT+11:00) Pacific/Bougainville"
    },
    {
       "value":"Pacific/Efate",
       "name":"(GMT+11:00) Pacific/Efate"
    },
    {
       "value":"Pacific/Guadalcanal",
       "name":"(GMT+11:00) Pacific/Guadalcanal"
    },
    {
       "value":"Pacific/Kosrae",
       "name":"(GMT+11:00) Pacific/Kosrae"
    },
    {
       "value":"Pacific/Noumea",
       "name":"(GMT+11:00) Pacific/Noumea"
    },
    {
       "value":"Pacific/Pohnpei",
       "name":"(GMT+11:00) Pacific/Pohnpei"
    },
    {
       "value":"Asia/Anadyr",
       "name":"(GMT+12:00) Asia/Anadyr"
    },
    {
       "value":"Asia/Kamchatka",
       "name":"(GMT+12:00) Asia/Kamchatka"
    },
    {
       "value":"Pacific/Fiji",
       "name":"(GMT+12:00) Pacific/Fiji"
    },
    {
       "value":"Pacific/Funafuti",
       "name":"(GMT+12:00) Pacific/Funafuti"
    },
    {
       "value":"Pacific/Kwajalein",
       "name":"(GMT+12:00) Pacific/Kwajalein"
    },
    {
       "value":"Pacific/Majuro",
       "name":"(GMT+12:00) Pacific/Majuro"
    },
    {
       "value":"Pacific/Nauru",
       "name":"(GMT+12:00) Pacific/Nauru"
    },
    {
       "value":"Pacific/Norfolk",
       "name":"(GMT+12:00) Pacific/Norfolk"
    },
    {
       "value":"Pacific/Tarawa",
       "name":"(GMT+12:00) Pacific/Tarawa"
    },
    {
       "value":"Pacific/Wake",
       "name":"(GMT+12:00) Pacific/Wake"
    },
    {
       "value":"Pacific/Wallis",
       "name":"(GMT+12:00) Pacific/Wallis"
    },
    {
       "value":"Antarctica/McMurdo",
       "name":"(GMT+13:00) Antarctica/McMurdo"
    },
    {
       "value":"Pacific/Apia",
       "name":"(GMT+13:00) Pacific/Apia"
    },
    {
       "value":"Pacific/Auckland",
       "name":"(GMT+13:00) Pacific/Auckland"
    },
    {
       "value":"Pacific/Enderbury",
       "name":"(GMT+13:00) Pacific/Enderbury"
    },
    {
       "value":"Pacific/Fakaofo",
       "name":"(GMT+13:00) Pacific/Fakaofo"
    },
    {
       "value":"Pacific/Tongatapu",
       "name":"(GMT+13:00) Pacific/Tongatapu"
    },
    {
       "value":"Pacific/Chatham",
       "name":"(GMT+13:45) Pacific/Chatham"
    },
    {
       "value":"Pacific/Kiritimati",
       "name":"(GMT+14:00) Pacific/Kiritimati"
    }
 ];