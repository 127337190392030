export enum DeviceAlertTypeSummary {
    ETHERNET_INTERFACE_CHAGNE = "ethernetInterfaceChange",
    WEB_UI_LOGIN_FAILURE = "webUILoginFailure",
    BANDWIDTH = "bandwidth",
    CELL_BOUNCING = "cellBouncing",
    CONFIGURATION = "config",
    DOWNTIME = "downtime",
    FAILOVER = "failover",
    FIRMWARE = "fw",
    IP_CHANGE = "ipChange",
    UNIT_POWERED_UP = "reboot",
    RF = "rf",
    TEMPERATURE = "temperature",
    DRY_CONTACT = "dryContact",
    NETWORK_TECHNOLOGY_CHANGE = "networkTechnologyChange",
    SERIAL_CONNECTOR = "serialConnector"
};

export enum DeviceAlertType {
    ETHERNET_INTERFACE_CHAGNE = "ethernetInterfaceChange",
    WEB_UI_LOGIN_FAILURE = "webUILoginFailure",
    BANDWIDTH = "bandwidth",
    CELL_BOUNCING = "cellBouncing",
    CONFIGURATION = "config",
    DOWNTIME = "downtime",
    FAILOVER = "failover",
    FIRMWARE = "fw",
    IP_CHANGE = "ipChange",
    UNIT_POWERED_UP = "reboot",
    TEMPERATURE = "temperature",
    LICENSE = "license",
    RSRP = "RSRP",
    RSRQ = "RSRQ",
    RSSI = "RSSI",
    SINR = "SINR",
    NR_RSRP = "NR_RSRP",
    NR_RSRQ = "NR_RSRQ",
    NR_SINR = "NR_SINR",
    DRY_CONTACT = "dryContact",
    NETWORK_TECHNOLOGY_CHANGE = "networkTechnologyChange",
    SERIAL_CONNECTOR = "serialConnector"
};

export type DeviceAlertTypeNameMap = {
    [key in DeviceAlertType]: string
};

export type DeviceAlertTypeSummaryInitial = {
    [key in DeviceAlertTypeSummary]: number
};