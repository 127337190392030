/******* Angular Resourse *******/
import { Injectable } from '@angular/core';

/******* Shared Resourse *******/
import { UtilsService } from './utils.service';
import { PrivilegeService } from './privilege.service';
import { GlobalUIBlockingService } from './global-ui-blocking.service';
import { FileType } from '@core/types';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

/******* API Resourse *******/
import { Files } from '@api';

@Injectable({
    providedIn: 'root',
})
export class FileService {

    // Public
    public currentFirmwareList: Observable<any>;
    public currentConfigurationList: Observable<any>;

    // Private
    private currentFirmwareListSubject: BehaviorSubject<any>;
    private currentConfigurationListSubject: BehaviorSubject<any>;

    /**
     * Constructor
     * 
     * @param {Files} _files
     * @param {UtilsService} _utilsService
     * @param {PrivilegeService} _privilegeService
     */
    constructor(
        private _files: Files,
        private _utilsService: UtilsService,
        private _privilegeService: PrivilegeService,
        private _globalUIBlockingService: GlobalUIBlockingService) {

        // Firmware initial
        this.currentFirmwareListSubject = new BehaviorSubject<Array<any>>(null);
        this.currentFirmwareList = this.currentFirmwareListSubject.asObservable()
            .pipe(tap((x) => { if (!x) this._queryFiles(FileType.FIRMWARE); }));

        // Configuration initial
        this.currentConfigurationListSubject = new BehaviorSubject<Array<any>>(null);
        this.currentConfigurationList = this.currentConfigurationListSubject.asObservable()
            .pipe(tap((x) => { if (!x) this._queryFiles(FileType.CONFIGURATION); }));
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get currrent firmware list Value
     */
    get currentFirmwareListValue() {
        return this.currentFirmwareListSubject.value;
    }

    /**
     * Get currrent configuration list Value
     */
    get currentConfigurationValue() {
        return this.currentConfigurationListSubject.value;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query Device Status Data
     * 
     * @param {FileType} fileType
     * @param resolve
     * @param rejects
     */
    private _queryFiles(fileType: FileType, resolve = null, rejects = null): void {
        this._privilegeService.currentUser.pipe(filter(x => x != null), take(1)).subscribe((userData) => {
            const owner = userData.id;
            let conditionArray: Array<any> = [
                { fileType },
                { userId: owner } // shared file
            ];
            this._globalUIBlockingService.start();
            this._files.getFiles({
                order: "createdAt DESC",
                limit: 1000,
                where: {
                    and: [...conditionArray]
                }
            }).subscribe((returnData) => {
                this._globalUIBlockingService.stop();
                const fileListData = returnData.data;
                switch (fileType) {
                    case FileType.FIRMWARE:
                        this.currentFirmwareListSubject.next(fileListData);
                        break;
                    case FileType.CONFIGURATION:
                        this.currentConfigurationListSubject.next(fileListData);
                        break;
                }
                if (resolve) resolve(fileListData);
            }, (error) => {
                this._globalUIBlockingService.stop();
                this._utilsService.alert('error', `Fail to load ${fileType}`, error);
                if (rejects) rejects(false);
            });
        });
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh files by type
     * 
     * @param {FileType} fileType
     * @returns {Promise<any>}
     */
    public refreshFiles(fileType: FileType): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._queryFiles(fileType, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Clear the files by type
     * 
     * @param {FileType} fileType
     */
    public clearAllInfo(fileType: FileType): void {
        switch (fileType) {
            case FileType.FIRMWARE:
                this.currentFirmwareListSubject.next(null);
                break;
            case FileType.CONFIGURATION:
                this.currentConfigurationListSubject.next(null);
                break;
        };
    }
}