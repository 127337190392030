export enum TaskTypeValue {
    REBOOT = "reboot",
    // RESET = "reset",
    UPGRADE_FIRMWARE_WITH_CURRENT_SETTING = "updateFW",
    UPGRADE_FIRMWARE_WITH_FACTORY_DEFAULT_SETTING = "updateFWFactory",
    // UPGRADE_CONFIGURATION = "updateConfig",
    // AUTO_CONFIGURATION_BACKUP = "autoCfgBackup",
    // RECURSIVE_REPORT_EMAIL = "recursiveReport",
    // FCC_SPEED_TEST = "FCCTests",
    SPEED_TEST_TO_EMAIL = "FCCTests_MSP",
    PROFILE_IMPLEMENT = "profileImplement"
};

export enum ProfileImplementType {
    BY_GROUP = "byGroup",
    BY_Filter = "byFilter"
};

export type TaskTypeNameMapping = {
    [key in TaskTypeValue]: string
};