<div class="auth-wrapper auth-v1 px-2 twofa-section">
	<div class="global-rllipse"></div>
	<div class="auth-inner py-2">
		<div class="card mb-0">
			<div class="card-body">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="twofa-form-header">
					<h2 [translate]="'TWO_FACTOR_AUTH.GREETING.TITLE'">Two-factor Authentication</h2>
					<p [translate]="'TWO_FACTOR_AUTH.GREETING.TEXT'">Enter authentication code</p>
				</div>

				<!-- Alternative -->
				<!-- <p class="alternative-text"
					*ngIf="queryParameter.backuptype == 'GA' || queryParameter.backuptype == 'SMS'"
					(click)="alternativeCode()">
					Switch to
					<span *ngIf="queryParameter.backuptype == 'GA'"> Google Authenticator</span>
					<span *ngIf="queryParameter.backuptype == 'SMS'"> SMS</span>
				</p> -->

				<!-- Reset 2FA -->
				<!-- <p class="Reset-text mb-0 twofa-reset" (click)="reset2FA()">Reset 2FA</p> -->

				<!-- Code Panel -->
				<form class="mt-2" [formGroup]="codePanelForm" (ngSubmit)="onSubmit()">

					<!-- Digit Input -->
					<div class="form-group" (keypress)="numericOnly($event)">
						<input type="text" formControlName="code" class="form-control code-input"
							[ngClass]="{ 'is-invalid': submitted && f.code.errors }" id="twofa-code" maxlength="6" />
						<div *ngIf="submitted && f.code.errors" class="invalid-feedback">
							<ng-container *ngIf="f.code.errors.required">{{'FORM.ERROR_MESSAGE.REQUIRED' |
								translate}}</ng-container>
						</div>
					</div>

					<!-- <div class="form-group code-panel d-flex justify-content-between"
						(keyup)="keyupEventHandler($event)" (keypress)="numericOnly($event)">
						<ng-container *ngFor="let count of codeCountArray; let i = index">
							<input #input type="text" maxlength="1" class="form-control" [name]="'Code'+ i"
								[tabindex]="i" [formControlName]="'Code'+ i"
								[ngClass]="{'error': submitted && f['Code'+ i].errors}"/>
						</ng-container>
					</div> -->

					<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block" rippleEffect>
						<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
						{{'FORM.BUTTON_TEXT.VERIFY_MY_ACCOUNT'|translate}}
					</button>
				</form>

				<p class="text-center mt-2">
					<a routerLink="/login" id="back-to-login">
						<i data-feather="chevron-left" class="mb-25"></i> {{'FORM.BUTTON_TEXT.BACK_TO_LOGIN'|translate}}
					</a>
				</p>
			</div>
		</div>
	</div>
</div>