import { AccountTypePermissions, CoreMenuItem, MenuPermissions, TenantType } from '@core/types'

export const MENU: Array<CoreMenuItem> = [
    {
        id: 'DASHBOARDS',
        title: 'Dashboards',
        type: 'collapsible',
        icon: 'home',
        translate: "MENU.DASHBOARDS.TITLE",
        children: [
            {
                id: 'ANALYTICS',
                title: 'Analytics',
                type: 'item',
                icon: 'circle',
                translate: "MENU.DASHBOARDS.ANALYTICS",
                url: 'pages/analytics'
            },
            {
                id: 'CELLANALYSIS',
                title: 'Cell Analysis',
                type: 'item',
                icon: 'circle',
                translate: "MENU.DASHBOARDS.CELLANALYSIS",
                url: 'pages/cellAnalysis'
            }
        ]
    },
    {
        id: 'DEVICES',
        title: 'Devices',
        type: 'item',
        icon: 'cpu',
        translate: "MENU.DEVICES",
        url: 'pages/devicelist'
    },
    {
        id: 'ALERTS',
        title: 'Alerts',
        type: 'collapsible',
        icon: 'alert-triangle',
        translate: "MENU.ALERTS.TITLE",
        children: [
            {
                id: 'HISTORY',
                title: 'History',
                type: 'item',
                icon: 'circle',
                translate: "MENU.ALERTS.HISTORY",
                url: 'pages/alertshistory'
            },
            {
                id: 'EMAILTEMPLATE',
                title: 'Email Template',
                type: 'item',
                icon: 'circle',
                translate: "MENU.ALERTS.EMAIL_TEMPLATE",
                url: 'pages/alertsemailtemplate'
            }
        ]
    },
    {
        id: 'SCHEDULER',
        title: 'Scheduler',
        type: 'item',
        icon: 'calendar',
        translate: "MENU.SCHEDULER",
        url: 'pages/scheduler'
    },
    {
        id: 'GROUPBUILDER',
        title: 'Groups',
        type: 'item',
        icon: 'package',
        translate: "MENU.GROUPS",
        url: 'pages/group-list'
    },
    {
        id: 'PROFILETEMPLATE',
        title: 'Profile Template',
        type: 'item',
        icon: 'book',
        translate: "MENU.PROFILE_TEMPLATE",
        url: 'pages/profile-template'
    },
    {
        id: 'FILEMANAGEMENT',
        title: 'Files Management',
        type: 'item',
        icon: 'server',
        translate: "MENU.FILE_MANAGEMENT",
        url: 'pages/files-management'
    },
    {
        id: 'REPORT',
        title: 'Report',
        type: 'item',
        icon: 'download-cloud',
        translate: "MENU.REPORT",
        url: 'pages/reports'
    },
    {
        id: 'MAP',
        title: 'Map',
        type: 'item',
        icon: 'map',
        translate: "MENU.MAP",
        url: 'pages/map'
    },
    /* {
        id: 'LOGS',
        title: 'Logs',
        type: 'collapsible',
        icon: 'file-text',
        children: [
            {
                id: 'AUDITLOGS',
                title: 'Audit',
                type: 'item',
                icon: 'circle',
                url: 'pages/auditlogs'
            },
            {
                id: 'DOMAINPROXYLOGS',
                title: 'Domain Proxy',
                type: 'item',
                icon: 'circle',
                url: 'pages/domainproxylogs'
            }
        ]
    }, */
    {
        id: 'ACCOUNTSETTINGS',
        title: 'Account Settings',
        type: 'item',
        icon: 'settings',
        translate: "MENU.ACCOUNT_SETTINGS",
        url: 'pages/account-settings'
    },
    /* {
        id: 'RESTAPI',
        title: 'API',
        type: 'item',
        icon: 'coffee',
        url: 'pages/rest-api'
    }, */
    {
        id: 'SUPPORT',
        title: 'Support',
        type: 'item',
        icon: 'help-circle',
        externalUrl: true,
        openInNewTab: true,
        translate: "MENU.SUPPORT",
        url: 'http://helpdesk.becentral.io/'
    }
];

export const ACCOUNT_TYPE_PAGE_BLOCK: AccountTypePermissions = {
    [TenantType.ISP]: { ALERTS: true, RESTAPI: true },
    [TenantType.ISP_PLUS]: { RESTAPI: true },
    [TenantType.ISP_PRO]: {},
    [TenantType.MSP_PARTNER_ENTERPRISE]: {},
    [TenantType.EVAL]: {},
    [TenantType.DEFAULT]: {},
    [TenantType.TRIAL]: {}
};

export const NOT_ADMIN_BLOCK: MenuPermissions = {
    EMAILTEMPLATE: true,
    // ACCOUNTSETTINGS: true,
    LOGS: true,
    AUDITLOGS: true,
    DOMAINPROXYLOGS: true
};

export const DEFAULT_TENANT_BLOCK: MenuPermissions = {
    DASHBOARDS: true,
    ANALYTICS: true,
    CELLANALYSIS: true,
    ALERTS: true,
    HISTORY: true,
    EMAILTEMPLATE: true,
    SCHEDULER: true,
    GROUPBUILDER: true,
    PROFILETEMPLATE: true,
    MAP: true,
    RESTAPI: true,
    LOGS: true,
    AUDITLOGS: true,
    DOMAINPROXYLOGS: true
};