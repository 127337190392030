import { TaskTypeValue } from "@core/types";

export const TASK_TYPE_LIST = [
    { name: "Reboot", value: TaskTypeValue.REBOOT },
    /* { name: "Reset to Default", value: "reset" }, */
    { name: "Upgrade Firmware with Current Setting", value: TaskTypeValue.UPGRADE_FIRMWARE_WITH_CURRENT_SETTING },
    { name: "Upgrade Firmware with Factory Default Setting", value: TaskTypeValue.UPGRADE_FIRMWARE_WITH_FACTORY_DEFAULT_SETTING },
    /* { name: "Upgrade Configuration", value: "updateConfig" },
    { name: "Group", value: "group" },
    { name: "Auto Configuration Backup", value: "autoCfgBackup" },
    { name: "Recursive Report to Email", value: "recursiveReport" },
    { name: "FCC Speed Test (A-CAM & CAF)", value: "FCCTests" }, */
    { name: "Speed Test to Email", value: TaskTypeValue.SPEED_TEST_TO_EMAIL },
    { name: "Profile Implement", value: TaskTypeValue.PROFILE_IMPLEMENT }
];

export const WEEK_LIST = [
    { name: "Monday", value: "0" },
    { name: "Tuesday", value: "1" },
    { name: "Wednesday", value: "2" },
    { name: "Thursday", value: "3" },
    { name: "Friday", value: "4" },
    { name: "Saturday", value: "5" },
    { name: "Sunday", value: "6" }
];

export const MONTH_LIST = [
    { name: 'January', value: "0" },
    { name: 'February', value: "1" },
    { name: 'March', value: "2" },
    { name: 'April', value: "3" },
    { name: 'May', value: "4" },
    { name: 'June', value: "5" },
    { name: 'July', value: "6" },
    { name: 'August', value: "7" },
    { name: 'September', value: "8" },
    { name: 'October', value: "9" },
    { name: 'November', value: "10" },
    { name: 'December', value: "11" }
];