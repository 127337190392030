import { Injectable } from "@angular/core";
import { SUPPORT_LANGUAGE } from "@core/constant";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CoreTranslatingService {

    // Private
    private _currentLang: string = "";

    /**
     * Constructor
     * 
     * @param {TranslateService} _translateService
     */
    constructor(private _translateService: TranslateService) {
        _translateService.addLangs(this.languageOptions.map(x => x.value));
        _translateService.setDefaultLang('en');
        _translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            const { lang } = event;
            localStorage.setItem('becentral_language', lang);
        });
        const useLang = localStorage.getItem('becentral_language');
        const browserLang = _translateService.langs.includes(_translateService.getBrowserCultureLang()) ? _translateService.getBrowserCultureLang() : 'en';
        _translateService.use('en'); // useLang || browserLang
    }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * get translation service
     */
    get translateService() {
        return this._translateService;
    }

    /**
     * get all support language options
     */
    get languageOptions() {
        return SUPPORT_LANGUAGE;
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * use the current language
     * 
     * @param {string} language 
     */
    public useLanguage(language: string): void {
        this._translateService.use(language);
    }

    /**
     * add new translation into language
     * 
     * @param {string} language 
     * @param {any} translation 
     */
    public setLanguage(language: string, translation: any): void {
        this._translateService.setTranslation(language, translation, true);
    }

    /**
     * get translation into language
     * 
     * @param {string} key
     */
    public getTranslation(key: string): Observable<string> {
        return this._translateService.get(key);
    }

}