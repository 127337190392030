<div class="auth-wrapper auth-v2">
	<div class="auth-inner row m-0 pb-0" style="overflow-x: hidden;">

		<div class="global-rllipse d-block d-lg-none"></div>

		<!-- Left Section-->
		<div class="d-flex col-lg-5 align-items-center auth-bg px-3 p-lg-5 forgot-password-form-section">
			<div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="forgot-password-form-header">
					<h1>{{'FORGOT_PASSWORD.GREETING.TITLE' | translate}}</h1>
					<p>{{'FORGOT_PASSWORD.GREETING.TEXT' | translate}}</p>
				</div>
				<div class="d-flex align-items-center justify-content-center">
					<form class="auth-forgot-password-form mt-50" [formGroup]="forgotPasswordForm"
						(ngSubmit)="onSubmit()">
						<div class="form-group">
							<label class="form-label" for="forgot-password-email">{{'FORM.LABEL.EMAIL' |
								translate}}</label>
							<input type="text" class="form-control" formControlName="email"
								placeholder="john@example.com" aria-describedby="forgot-password-email" autofocus=""
								tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
							<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
								<ng-container *ngIf="f.email.errors.required">{{'FORM.ERROR_MESSAGE.REQUIRED' |
									translate}}</ng-container>
								<ng-container *ngIf="f.email.errors.email">{{'FORM.ERROR_MESSAGE.REQUIRED' |
									translate}}</ng-container>
							</div>
						</div>

						<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block mt-2"
							tabindex="2" rippleEffect>
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
							{{'FORM.BUTTON_TEXT.SEND_RESET_LINK' | translate}}
						</button>

					</form>
				</div>

				<p class="text-center mt-2">
					<a id="back-to-login" routerLink="/login">
						<i data-feather="chevron-left" class="mb-25"></i> {{'FORM.BUTTON_TEXT.BACK_TO_LOGIN' |
						translate}}
					</a>
				</p>
			</div>
		</div>

		<!-- Right Section -->
		<div class="d-none d-lg-flex col-lg-7 forgot-password-illustration-section">
			<div class="w-100 forgot-password-illustration-image">
				<div class="rllipse"></div>
				<img src="assets/images/pages/forgot-password-v2-dark.svg" alt="Forgot password" />
			</div>
		</div>
	</div>
</div>