import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { combineLatest, merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';
import { PrivilegeService } from '@core/services/privilege.service';
import { GlobalUIBlockingService } from '@core/services/global-ui-blocking.service';
import { UtilsService } from '@core/services/utils.service';
import { AccessTokenService } from '@core/services/access-token.service';
import { AccountSwitchService } from '@core/services/account-switch.service';

import { Auth, Tenants } from '@api';

import { Router } from '@angular/router';
import { IdleCheckService } from '@core/services/idle-check.service';
import { RoleName, UserPreferanceSettingsItem } from '@core/types';
import { GroupService } from '@core/services/group-service';
import { RoleNameFilterPipe } from '@core/pipes/conversion-pipe';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {

	// Public
	public currentUser: any;
	public coreConfig: any;
	public currentSkin: string;
	public prevSkin: string;
	public navigation: any;
	public tenantsArray: Array<any> = [];

	@HostBinding('class.fixed-top')
	public isFixed = false;

	@HostBinding('class.navbar-static-style-on-scroll')
	public windowScrolled = false;

	// Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (
			(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
			this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
			this.coreConfig.layout.type == 'horizontal'
		) {
			this.windowScrolled = true;
		} else if (
			(this.windowScrolled && window.pageYOffset) ||
			document.documentElement.scrollTop ||
			document.body.scrollTop < 10
		) {
			this.windowScrolled = false;
		}
	}

	// Private
	private _unsubscribeAll$: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {Router} _router
	 * @param {Auth} _auth
	 * @param {Tenants} _tenants
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {CoreMediaService} _coreMediaService
	 * @param {MediaObserver} _mediaObserver
	 * @param {PrivilegeService} _privilegeService
	 * @param {GlobalUIBlockingService} _globalUIBlockingService
	 * @param {UtilsService} _utilsService
	 * @param {AccessTokenService} _accessTokenService
	 * @param {AccountSwitchService} _accountSwitchService
	 * @param {IdleCheckService} _idleCheckService
	 * @param {GroupService} _groupService
	 */
	constructor(private _router: Router,
		private _auth: Auth,
		private _tenants: Tenants,
		private _coreConfigService: CoreConfigService,
		private _coreMediaService: CoreMediaService,
		private _coreSidebarService: CoreSidebarService,
		private _mediaObserver: MediaObserver,
		private _privilegeService: PrivilegeService,
		private _globalUIBlockingService: GlobalUIBlockingService,
		private _utilsService: UtilsService,
		private _accessTokenService: AccessTokenService,
		private _accountSwitchService: AccountSwitchService,
		private _idleCheckService: IdleCheckService,
		private _groupService: GroupService) {

		// Set the private defaults
		this._unsubscribeAll$ = new Subject();
	}

	// Accessor
	// -----------------------------------------------------------------------------------------------------

	/**
	 * get user Full Name
	 */
	get userFullName() {
		return this.currentUser ? `${this.currentUser.firstName} ${this.currentUser.lastName}` : "N A";
	}

	/**
	 * get tenant Name
	 */
	get tenantName() {
		return this.currentUser ? `${this.currentUser.tenant.name}` : "";
	}

	/**
	 * get whether is default Tenant
	 */
	get isDefaultTenant() {
		return this.currentUser ? this.currentUser.defaultTenant == this.currentUser.tenant.id : false;
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebar(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}

	/**
	 * Toggle Dark Skin
	 */
	toggleDarkSkin() {
		// Get the current skin
		this._coreConfigService
			.config
			.pipe(takeUntil(this._unsubscribeAll$))
			.subscribe(config => {
				this.currentSkin = config.layout.skin;
			});

		// Toggle Dark skin with prevSkin skin
		this.prevSkin = localStorage.getItem('prevSkin');
		let skin = 'dark';

		if (this.currentSkin === 'dark') {
			this._coreConfigService.setConfig(
				{ layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
				{ emitEvent: true }
			);
			skin = this.prevSkin ? this.prevSkin : 'default';
		} else {
			localStorage.setItem('prevSkin', this.currentSkin);
			this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
		}
		const currentPreferenceSettings = this._privilegeService.currentUserValue.preferenceSettings;
		let preferenceSettings: UserPreferanceSettingsItem = {
			...currentPreferenceSettings,
			Skin: skin
		};
		this._privilegeService.updateUserData({ preferenceSettings });
	}

	/**
	 * Logout method
	 */
	logout() {
		this._idleCheckService.logout();
	}

	/**
	 * Swap the Tenants
	 */
	swapTenant() {
		this._tenants.getTenantAccess(this.currentUser.defaultTenant).subscribe((returnData) => {
			this._accountSwitchService.switchAccount(returnData.data); // Switch Account
		}, (error) => { this._utilsService.alert("error", "Fail to Swap the tenants", error); })
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		// Subscribe to the config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll$)).subscribe(config => {
			this.coreConfig = config;
			this.currentSkin = config.layout.skin;

			// Fix: for vertical layout if default navbar fixed-top than set isFixed = true
			setTimeout(() => {
				if (this.coreConfig.layout.navbar.type === 'fixed-top') this.isFixed = true;
			}, 0);
		});

		const user = this._privilegeService.currentUser.pipe(takeUntil(this._unsubscribeAll$), filter(item => item != null));
		const group = this._groupService.currentGroupList.pipe(takeUntil(this._unsubscribeAll$), filter(item => item != null));
		combineLatest([user, group]).subscribe((returnData) => {
			const privilegeData = returnData[0];
			this.currentUser = {
				...privilegeData,
				role: privilegeData.role === RoleName.TENANT_GROUP_ADMIN ? '' : privilegeData.role,
				isGroupAdmin: privilegeData.role === RoleName.TENANT_GROUP_ADMIN
			};

			if (privilegeData.role === RoleName.TENANT_GROUP_ADMIN) {
				this.currentUser = {
					...this.currentUser,
					groupRoles: privilegeData.groups.map((groupRole) => {
						return `${RoleNameFilterPipe.mapGroupRoleName(groupRole.name)} (${groupRole.groupName})`;
					}).join(', ')
				}
			}

		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll$.next();
		this._unsubscribeAll$.complete();
	}
}
