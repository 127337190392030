/******* Angular Resourse *******/
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

/******* Core Resourse *******/
import { PrivilegeService } from './privilege.service';
import { DeviceStatusService } from './device-status.service';
import { TagsService } from './tags.service';
import { GlobalUIBlockingService } from './global-ui-blocking.service';
import { AccessTokenService } from './access-token.service';
import { GroupService } from './group-service';
import { AccessTokenPackage, FileType } from '@core/types';
import { RfStandardService } from './rf-standard.service';
import { CoreConfigService } from './config.service';
import { FileService } from './file-service';
import { AlertService } from './alert.service';
import { SocketService } from './socket.service';
import { ProfileTemplateService } from './profile-template.service';

@Injectable({
    providedIn: 'root',
})
export class AccountSwitchService {

    /**
     * Constructor
     *
     * @param {Router} _router 
     * @param {PrivilegeService} _privilegeService
     * @param {AccessTokenService} _accessTokenService
     * @param {GlobalUIBlockingService} _globalUIBlockingService
     * @param {DeviceStatusService} _deviceStatusService
     * @param {TagsService} _tagsService
     * @param {GroupService} _groupService
     * @param {RfStandardService} _rfStandardService
     * @param {FileService} _fileService
     * @param {AlertService} _alertService
     * @param {CoreConfigService} _coreConfigService
     * @param {SocketService} _socketService
     * @param {ProfileTemplateService} _profileTemplateService
     */
    constructor(private _router: Router,
        private _privilegeService: PrivilegeService,
        private _accessTokenService: AccessTokenService,
        private _globalUIBlockingService: GlobalUIBlockingService,
        private _deviceStatusService: DeviceStatusService,
        private _tagsService: TagsService,
        private _groupService: GroupService,
        private _rfStandardService: RfStandardService,
        private _fileService: FileService,
        private _alertService: AlertService,
        private _coreConfigService: CoreConfigService,
        private _socketService: SocketService,
        private _profileTemplateService: ProfileTemplateService) { }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Clear all storage before switching account
     */
    private clearAllStorage(): void {
        this._privilegeService.clearUserInfo();
        this._deviceStatusService.clearAllInfo();
        this._tagsService.clearAllInfo();
        this._groupService.clearAllInfo();
        this._rfStandardService.clearAllInfo();
        this._fileService.clearAllInfo(FileType.FIRMWARE);
        this._fileService.clearAllInfo(FileType.CONFIGURATION);
        this._alertService.clearAllInfo();
        this._socketService.disconnectSocket();
        this._profileTemplateService.clearAllInfo();
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Swtich the Account
     * 
     * @param {AccessTokenPackage} data
     */
    public switchAccount(data: AccessTokenPackage): void {
        this.clearAllStorage();
        this._globalUIBlockingService.start();
        this._accessTokenService.setToken(data);
        this._router.navigate(['tenant-access']);
        Promise.all([
            this._groupService.refreshGroups(),
            this._privilegeService.refreshUserData()
        ]).then(() => {
            this._socketService.initialSocketConnection(); // initial current socket
            const userInfo = this._privilegeService.currentUserValue;
            const firstRoute = userInfo.CustID === "DEFAULT" && new Set(userInfo.permissions).has("SuperAdminAccess") ? ['pages/account-settings'] : ['/pages'];

            this._router.navigate(firstRoute, { replaceUrl: true }).then(() => {
                this._globalUIBlockingService.stop();

                // set config
                const skin = this._privilegeService.currentUserValue?.preferenceSettings?.Skin;
                if (skin) this._coreConfigService.config = { layout: { skin } };
                else this._coreConfigService.config = { layout: { skin: "dark" } };
            });
        }).catch(() => {
            this._router.navigate(['login'], { replaceUrl: true });
        });
    }

}