import { Component } from "@angular/core";
import { SUPPORT_LANGUAGE } from "@core/constant";
import { CoreTranslatingService } from "@core/services/core-translating.service";

@Component({
    selector: 'language-selector',
    templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent {

    // Public 
    public languageOptions = {};

    // Private
    private _currentLang: string = "en";

    /**
     * Constructor
     */
    constructor(public _coreTranslatingService: CoreTranslatingService) {
        SUPPORT_LANGUAGE.forEach(language => {
            this.languageOptions[language.value] = language;
        });
    }

    // Accessor
    // 

    /**
     * get translate service
     */
    get translateService() {
        return this._coreTranslatingService.translateService;
    }

    /**
     * Set Language
     * 
     * @param {string} lang 
     */
    public setLanguage(lang: string): void {
        this._coreTranslatingService.useLanguage(lang);
    }

}