import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/* URL Requirement */
export function speedTestUrlValidator(): ValidatorFn {
    
    let urlFormat: RegExp = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)?$/;

    return (control: AbstractControl): ValidationErrors | null => {
        if(!control.value) return null;
        const format = urlFormat.test(control.value);
        return format ? null  : {url: true};
    };
}