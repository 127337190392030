// Device License Action List
export const LICENSE_ACTION_LIST = {
	assign: {
		name: "Assign",
		icon: "icon-bookmark"
	},
	unclaim: {
		name: "Unclaim",
		icon: "icon-delete",
	},
	transfer: {
		name: "Transfer",
		icon: "icon-shuffle"
	},
	renew: {
		name: "Renew",
		icon: "icon-rotate-cw"
	},
	reclaim: {
		name: "Reclaim",
		icon: "icon-corner-left-up"
	}
	/* delete: {
		name: "Delete",
		icon: "icon-x-circle",
		alert: "Deleted license(s) will be gone forever. Are you sure you want to Delete the License(s)?"
	} */
};

// Device ISP Price Chart
export const TRIAL_PRICE_CHART = {
	'TRIAL-MO-5LP': { cost: 0, total: 5 }
};

export const ISP_PRICE_CHART = {
	'ISP-MO-100LP': { cost: 125, total: 100 },
	'ISP-MO-200LP': { cost: 175, total: 200 },
	'ISP-MO-300LP': { cost: 250, total: 300 },
	'ISP-MO-400LP': { cost: 300, total: 400 },
	'ISP-MO-500LP': { cost: 350, total: 500 },
	'ISP-MO-1KLP': { cost: 400, total: 1000 },
	'ISP-MO-2KLP': { cost: 600, total: 2000 },
	'ISP-MO-3KLP': { cost: 750, total: 3000 },
	'ISP-MO-4KLP': { cost: 800, total: 4000 },
	'ISP-MO-5KLP': { cost: 850, total: 5000 },
	'ISP-MO-6KLP': { cost: 900, total: 6000 },
	'ISP-MO-7KLP': { cost: 950, total: 7000 },
	'ISP-MO-8KLP': { cost: 1000, total: 8000 },
	'ISP-MO-9KLP': { cost: 1150, total: 9000 },
	'ISP-MO-10KLP': { cost: 1250, total: 10000 }
};

export const ISP_PLUS_PRICE_CHART = {
	'ISP-MO-100LP': { cost: 150, total: 100 },
	'ISP-MO-200LP': { cost: 240, total: 200 },
	'ISP-MO-300LP': { cost: 300, total: 300 },
	'ISP-MO-400LP': { cost: 360, total: 400 },
	'ISP-MO-500LP': { cost: 420, total: 500 },
	'ISP-MO-1KLP': { cost: 480, total: 1000 },
	'ISP-MO-2KLP': { cost: 720, total: 2000 },
	'ISP-MO-3KLP': { cost: 900, total: 3000 },
	'ISP-MO-4KLP': { cost: 960, total: 4000 },
	'ISP-MO-5KLP': { cost: 1020, total: 5000 },
	'ISP-MO-6KLP': { cost: 1080, total: 6000 },
	'ISP-MO-7KLP': { cost: 1140, total: 7000 },
	'ISP-MO-8KLP': { cost: 1200, total: 8000 },
	'ISP-MO-9KLP': { cost: 1380, total: 9000 },
	'ISP-MO-10KLP': { cost: 1500, total: 10000 }
};

export const TENANT_ACCOUNT_TYPES = [
	{ name: "Enterprise(MSP/Partner)", value: "partner"},
	{ name: "Standard", value: "ISP"},
	{ name: "Plus", value: "ISP-Plus"},
	{ name: "Pro", value: "ISP-Pro"},
	/* { name: "Trial", value: "trial"} */
];