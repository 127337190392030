/*****************************************************
 * Import New Device Model
 * 
 * - Model key on enum "SupportDeviceModel" from support-device-model.ts
 * - Model name according to the model key on const "MODEL_NAME_MAP" from model-name-map.ts 
 * - Model image link on const "PRODUCT_IMAGE" from product-images.ts
 * - Key word for detecting model on const "MODEL_KEY_MAP" from model-key-map.ts
 * - Model feature on const "MODEL_FEATURES_MAP" from device-model-features
 * - Check other isModel
 * 
 *****************************************************/
export enum SupportDeviceModel {
    // Billion Model
    M_100 = "M_100",
    M_120_N = "M_120_N",
    M_150 = "M_150",
    M_300 = "M_300",
    M_300AX = "M_300AX",
    M_500 = "M_500",
    M_600 = "M_600",
    _4500 = "_4500",
    _4500AZ = "_4500AZ",
    _4500NZ = "_4500NZ",
    _4520AZ = "_4520AZ",
    _4700ZU = "_4700ZU",
    // BEC Model
    MX_200 = "MX_200",
    MX_200E = "MX_200E",
    MX_200A = "MX_200A",
    MX_200AE = "MX_200AE",
    MX_210_NP = "MX_210_NP",
    EG_210_N = "EG_210_N",
    MX_220 = "MX_220", // MX-220 Series
    MX_220_UT = "MX_220_UT", // MX-220 Series
    MX_221_P = "MX_221_P",
    MX_230 = "MX_230",
    MX_240 = "MX_240",
    MX_250 = "MX_250",
    MX_250E = "MX_250E",
    MX_241_NP = "MX_241_NP",
    MX_600 = "MX_600",
    MX_1000 = "MX_1000",
    MX_1000E = "MX_1000E",
    MX_1200 = "MX_1200",
    _4700 = "_4700",
    _4700AZ = "_4700AZ",
    _4900 = "_4900",
    _6200 = "_6200",
    _6300VNL = "_6300VNL",
    _6300NEL = "_6300NEL",
    _6500 = "_6500",
    _6600 = "_6600",
    _6800 = "_6800",
    _6900 = "_6900",
    _7000 = "_7000",
    _7000_R26_W = "_7000_R26_W",
    _8110 = "_8110",
    _8112 = "_8112",
    _8230 = "_8230",
    _8231 = "_8231",
    _8232 = "_8232",
    _8235 = "_8235",
    _8355 = "_8355",
    _8355P = "_8355P",
    _9900 = "_9900",
    // Specail Model
    _8920 = "_8920",
};

export interface ModelKeyObject {
    model: SupportDeviceModel;
    modelKeyWord: string;
};

export type ModelNameMap = {
    [key in SupportDeviceModel]: string;
};