import { NgModule } from "@angular/core";
import { LanguageSelectorComponent } from "./language-selector.component";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CoreCommonModule } from "@core/common.module";

@NgModule({
    declarations: [LanguageSelectorComponent],
    imports: [
        CommonModule,
        NgbModule,
        CoreCommonModule
    ],
    exports: [LanguageSelectorComponent]
})
export class LanguageSelectorModule { }