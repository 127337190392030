<div class="auth-wrapper auth-v2">
	<div class="auth-inner row m-0 pb-0" style="overflow-x: hidden;">

		<div class="global-rllipse d-block d-lg-none"></div>

		<!-- Left Section-->
		<div class="d-flex col-lg-5 align-items-center auth-bg px-1 px-lg-2 login-form-section">
			<div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="login-form-header">
					<h1 [translate]="'LOGIN.GREETING.TITLE'">Welcome to BECentral!</h1>
					<p [translate]="'LOGIN.GREETING.TEXT'">Please sign-in to your account and start the adventure.</p>
				</div>

				<!-- Error Message -->
				<ngb-alert id="errorMessage" [type]="'danger'" [dismissible]="false" *ngIf="errorMessage">
					<div class="alert-body">
						<p>{{ errorMessage }}</p>
					</div>
				</ngb-alert>

				<!-- Login Form -->
				<div class="d-flex align-items-center justify-content-center">
					<form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
						<div class="form-group">
							<label class="form-label" for="login-email" [translate]="'FORM.LABEL.EMAIL'">Email</label>
							<input type="text" formControlName="username" class="form-control" #usernameRef
								[ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="admin@bec.com"
								id="login-email" autofocus="autofocus" tabindex="1" autocomplete="new-email" />
							<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
								<ng-container *ngIf="f.username.errors.required">{{'FORM.ERROR_MESSAGE.REQUIRED' |
									translate}}</ng-container>
							</div>
						</div>

						<div class="form-group">
							<label for="login-password" [translate]="'FORM.LABEL.PASSWORD'">Password</label>
							<div class="input-group input-group-merge form-password-toggle"
								[ngClass]="{ 'is-invalid': submitted && f.password.errors }">
								<input [type]="isPasswordDisplay ? 'text' : 'password'" formControlName="password"
									[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
									class="form-control form-control-merge" placeholder="············" #passwordRef
									id="login-password" tabindex="2" autocomplete="off" />
								<div class="input-group-append">
									<span class="input-group-text cursor-pointer">
										<i class="feather font-small-4" [ngClass]="{
											'icon-eye-off': isPasswordDisplay,
											'icon-eye': !isPasswordDisplay
											}" (click)="togglePasswordType()">
										</i>
									</span>
								</div>
							</div>
							<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
								<ng-container *ngIf="f.password.errors.required">{{'FORM.ERROR_MESSAGE.REQUIRED' |
									translate}}</ng-container>
							</div>
						</div>

						<div class="d-flex justify-content-end">
							<a id="forget-password" routerLink="/forget-password">
								<small [translate]="'LOGIN.FORGOT_PASSWORD'">Forgot Password?</small>
							</a>
						</div>

						<!-- Sign In Button -->
						<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block mt-2"
							tabindex="3" rippleEffect [translate]="'FORM.BUTTON_TEXT.SIGN_IN'">
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Sign in
						</button>
						<p class="mt-2">
							This site is protected by reCAPTCHA and the Google
							<a href="https://policies.google.com/privacy">Privacy Policy</a> and
							<a href="https://policies.google.com/terms">Terms of Service</a> apply.
						</p>
					</form>
				</div>

			</div>
			<div class="language-selector-container">
				<ul class="nav navbar-nav align-items-center ml-auto">
					<language-selector></language-selector>
				</ul>
			</div>
		</div>

		<!-- Right Section -->
		<div class="d-none d-lg-block col-lg-7 login-illustration-section">
			<div class="login-title">
				<div class="title-text text-center">
					<p class="main-text font-large-1">Connecting the World for a Better Tomorrow</p>
					<p class="subtitle-text">Developing Connectivity Solutions that Create Opportunities and Drive
						Growth</p>
				</div>
			</div>
			<div class="w-100 login-illustration-image">
				<div class="rllipse"></div>
				<img src="assets/images/pages/login-illustration.png" alt="Login" />
			</div>
		</div>
	</div>
	<div class="version-tag text-muted max-z-index"><small>2.13.6</small></div>
</div>