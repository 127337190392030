import { DeviceAlertType } from "./alert-countings";

export enum AlertCategory {
    DEVICE = "device",
    SIGNAL = "signal",
    LICENSE = "license"
};

export interface AlertGlobalCountings {
    total: number;
    signal: number;
    hardware: number;
    license?: number;
    device: number;
};

/* export interface AlertNotification {
    device: Array<DeviceAlert>;
    signal: Array<DeviceAlert>;
    license: Array<DeviceAlert>;
}; */

export type AlertNotification = {
    [key in AlertCategory]: Array<DeviceAlert>;
};

export interface DeviceAlert {
    id: string;
    MAC: string;
    deviceId: string;
    IMSI?: string;
    Name?: string;
    level: "Alert" | "Caution";
    status: string;
    timestamp: string;
    type: DeviceAlertType;
    value?: string | number;
    FW?: string;
    RI?: string;
    DL_MCS?: string;
};

export type IsAlertNotificationLoading = {
    [key in AlertCategory]: boolean;
};

export type AlertNotificationLoadingCount = {
    [key in AlertCategory]: number;
};


