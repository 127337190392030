import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * IP Format Requirement
 * 
 * @returns {ValidatorFn}
 */
export function requiredIpFormat(): ValidatorFn {
    let ipReg: RegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    let returnObject: any = { requiredIpFormat: true };
    return (control: AbstractControl): ValidationErrors | null => {
        const format = ipReg.test(control.value) || control.value == "";
        return format ? null : returnObject;
    };
}

/**
 * Fixed Host MAC Format Requirement
 * 
 * @returns {ValidatorFn}
 */
export function requiredFixedHostMACFormat(): ValidatorFn {
    let macReg: RegExp = /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/;
    let returnObject: any = { requiredFixedHostMACFormat: true };
    return (control: AbstractControl): ValidationErrors | null => {
        const format = macReg.test(control.value) || control.value == "";
        return format ? null : returnObject;
    };
}

/**
 * Domain Format Requirement
 * 
 * @returns {ValidatorFn}
 */
export function requiredDomainFormat(): ValidatorFn {
    let domainReg: RegExp = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;
    let returnObject: any = { requiredDomainFormat: true };
    return (control: AbstractControl): ValidationErrors | null => {
        const format = domainReg.test(control.value) || control.value == "";
        return format ? null : returnObject;
    };
}

/**
 * Url Format Requirement
 * 
 * @returns {ValidatorFn}
 */
export function requiredUrlFormat(): ValidatorFn {

    let urlFormat: RegExp = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)?$/;

    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) return null;
        const format = urlFormat.test(control.value);
        return format ? null : { url: true };
    };
}

/**
 * Wireless WEP Key Format Requirement
 * 
 * @returns {ValidatorFn}
 */
export function requiredWirelessWep64BitsKeyFormat(): ValidatorFn {

    let keyFormat: RegExp = /^$|^[a-f0-9]{10}$/;

    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) return null;
        const format = keyFormat.test(control.value);
        return format ? null : { key: true };
    };
}

/**
 * Wireless WEP Key Format Requirement
 * 
 * @returns {ValidatorFn}
 */
export function requiredWirelessWep128BitsKeyFormat(): ValidatorFn {

    let keyFormat: RegExp = /^$|^[a-f0-9]{26}$/;

    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) return null;
        const format = keyFormat.test(control.value);
        return format ? null : { key: true };
    };
}

/**
 * Wireless Pre-Shared Key Format Requirement
 * 
 * @returns {ValidatorFn}
 */
export function requiredWirelessPreSharedKeyFormat(): ValidatorFn {

    let keyFormat: RegExp = /^.{8,63}$/;

    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) return null;
        const format = keyFormat.test(control.value);
        return format ? null : { key: true };
    };
}