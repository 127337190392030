import { DeviceAlertType, DeviceAlertTypeNameMap, DeviceAlertTypeSummary, DeviceAlertTypeSummaryInitial } from "@core/types";

export const DEVICE_ALERT_SUMMARY_INITIAL_VALUE: DeviceAlertTypeSummaryInitial = {
    [DeviceAlertTypeSummary.ETHERNET_INTERFACE_CHAGNE]: 0,
    [DeviceAlertTypeSummary.WEB_UI_LOGIN_FAILURE]: 0,
    [DeviceAlertTypeSummary.BANDWIDTH]: 0,
    [DeviceAlertTypeSummary.CELL_BOUNCING]: 0,
    [DeviceAlertTypeSummary.CONFIGURATION]: 0,
    [DeviceAlertTypeSummary.DOWNTIME]: 0,
    [DeviceAlertTypeSummary.FAILOVER]: 0,
    [DeviceAlertTypeSummary.FIRMWARE]: 0,
    [DeviceAlertTypeSummary.IP_CHANGE]: 0,
    [DeviceAlertTypeSummary.UNIT_POWERED_UP]: 0,
    [DeviceAlertTypeSummary.RF]: 0,
    [DeviceAlertTypeSummary.TEMPERATURE]: 0,
    [DeviceAlertTypeSummary.DRY_CONTACT]: 0,
    [DeviceAlertTypeSummary.NETWORK_TECHNOLOGY_CHANGE]: 0,
    [DeviceAlertTypeSummary.SERIAL_CONNECTOR]: 0
};

export const DEVICE_ALERT_TYPE_NAME_MAP: DeviceAlertTypeNameMap = {
    [DeviceAlertType.DOWNTIME]: "Downtime",
    [DeviceAlertType.IP_CHANGE]: "IP Change",
    [DeviceAlertType.UNIT_POWERED_UP]: "Unit powered up",
    [DeviceAlertType.FAILOVER]: "Failover",
    [DeviceAlertType.FIRMWARE]: "Firmware Upgraded",
    [DeviceAlertType.CONFIGURATION]: "Configuration Upgraded",
    [DeviceAlertType.ETHERNET_INTERFACE_CHAGNE]: "Ethernet Interface Change",
    [DeviceAlertType.WEB_UI_LOGIN_FAILURE]: "Web UI login failure",
    [DeviceAlertType.BANDWIDTH]: "Bandwidth",
    [DeviceAlertType.CELL_BOUNCING]: "Cell Bouncing",
    [DeviceAlertType.TEMPERATURE]: "Temperature",
    [DeviceAlertType.LICENSE]: "License",
    [DeviceAlertType.RSRP]: "RSRP",
    [DeviceAlertType.RSRQ]: "RSRQ",
    [DeviceAlertType.RSSI]: "RSSI",
    [DeviceAlertType.SINR]: "SINR",
    [DeviceAlertType.NR_RSRP]: "NR RSRP",
    [DeviceAlertType.NR_RSRQ]: "NR RSRQ",
    [DeviceAlertType.NR_SINR]: "NR SINR",
    [DeviceAlertType.DRY_CONTACT]: "Dry Contact (enclosure)",
    [DeviceAlertType.NETWORK_TECHNOLOGY_CHANGE]: "Network Technology Change",
    [DeviceAlertType.SERIAL_CONNECTOR]: "Serial Connector"
};

export const ALERT_TYPE_OPTIONS: Array<DeviceAlertType | 'all'> = [
    'all',
    DeviceAlertType.DOWNTIME,
    DeviceAlertType.IP_CHANGE,
    DeviceAlertType.UNIT_POWERED_UP,
    DeviceAlertType.FAILOVER,
    DeviceAlertType.FIRMWARE,
    DeviceAlertType.CONFIGURATION,
    DeviceAlertType.ETHERNET_INTERFACE_CHAGNE,
    DeviceAlertType.WEB_UI_LOGIN_FAILURE,
    DeviceAlertType.BANDWIDTH,
    DeviceAlertType.CELL_BOUNCING,
    DeviceAlertType.TEMPERATURE,
    DeviceAlertType.LICENSE,
    DeviceAlertType.RSRP,
    DeviceAlertType.RSRQ,
    DeviceAlertType.RSSI,
    DeviceAlertType.SINR,
    DeviceAlertType.NR_RSRP,
    DeviceAlertType.NR_RSRQ,
    DeviceAlertType.NR_SINR,
    DeviceAlertType.DRY_CONTACT,
    DeviceAlertType.NETWORK_TECHNOLOGY_CHANGE,
    DeviceAlertType.SERIAL_CONNECTOR
];