/******* Angular Resourse *******/
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from "@angular/router";

/******* Shared Resourse *******/
import { UtilsService } from './utils.service';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

/******* API Resourse *******/
import { Groups } from '@api';
import { GroupRegisterRequestBody, RoleRequest } from '@core/types';

@Injectable({
    providedIn: 'root',
})
export class GroupService {

    // public
    public currentGroupList: Observable<any>;

    // private
    private currentGroupListSubject: BehaviorSubject<any>;
    private currentGroupMapping: any = {};

    /**
     * Constructor
     *
     * @param {Router} _router
     * @param {Groups} _groups
     * @param {UtilsService} _utilsService
     */
    constructor(private _router: Router,
        private _groups: Groups,
        private _utilsService: UtilsService) {

        // User initial
        this.currentGroupListSubject = new BehaviorSubject<any>(null);
        this.currentGroupList = this.currentGroupListSubject.asObservable();

        // Check Access Token Enable to reload User or not 
        _router.events.pipe(filter(event => event instanceof RoutesRecognized), take(1)).subscribe((e: RoutesRecognized) => {
            const url = e.urlAfterRedirects;
            if (url.indexOf("/pages/") >= 0 && sessionStorage.getItem("becentralAccessToken")) {
                this.getGrouops();
            }
        });
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    // getter: currentGroupListValue
    public get currentGroupListValue(): any {
        return this.currentGroupListSubject.value;
    }

    // getter: currentGroupMappingValue
    public get currentGroupMappingValue(): any {
        return this.currentGroupMapping;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Groups List
     * 
     * @param resolve 
     * @param rejects 
     * @param forwardData
     */
    private getGrouops(resolve = null, rejects = null, forwardData = null): void {

        this._groups.getGroupsWithDevice().subscribe((returnData) => {
            returnData.data = returnData.data.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
            this.currentGroupListSubject.next(returnData);
            this.currentGroupMapping = {};
            returnData.data.forEach((group) => { this.currentGroupMapping[group._id] = group.name });
            if (resolve) resolve(forwardData ? forwardData : returnData);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to Load Group Data', error);
            if (resolve) rejects(error);
        });
    }

    /**
     * create new group
     * 
     * @param {GroupRegisterRequestBody} inputData 
     * @param resolve 
     * @param rejects 
     */
    private _createGroup(inputData: GroupRegisterRequestBody, resolve = null, rejects = null): void {
        this._groups.createGroup(inputData).subscribe((returnData) => {
            this.getGrouops(resolve, rejects, returnData);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to Create Group Data', error);
            if (rejects) rejects(error);
        });
    }

    /**
     * Update the Group by id
     * 
     * @param id 
     * @param inputData 
     * @param resolve 
     * @param rejects 
     */
    private _updateGroup(id: string, inputData: GroupRegisterRequestBody, resolve = null, rejects = null): void {

        this._groups.updateGroup(id, inputData).subscribe(() => {
            this.getGrouops(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to Update Group Data', error);
            if (rejects) rejects(error);
        });
    }

    /**
     * assign multiple device to the Group
     * 
     * @param id 
     * @param inputData 
     * @param resolve 
     * @param rejects 
     */
    private _assignGroup(id: string, inputData: string[], resolve = null, rejects = null): void {
        this._groups.assignGroupToDevice(id, inputData).subscribe(() => {
            this.getGrouops(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to Assign Group', error);
            if (rejects) rejects(error);
        });
    }

    /**
     * unassign multiple device from Group
     * 
     * @param inputData 
     * @param resolve 
     * @param rejects 
     */
    private _unassignGroup(inputData: string[], resolve = null, rejects = null): void {
        this._groups.unassignGroupFromDevice(inputData).subscribe(() => {
            this.getGrouops(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to Assign Group', error);
            if (rejects) rejects(error);
        });
    }

    /**
     * delete group
     * 
     * @param {string} id 
     * @param resolve 
     * @param rejects 
     */
    private _deleteGroup(id: string, resolve = null, rejects = null): void {

        this._groups.deleteGroup(id).subscribe(() => {
            this.getGrouops(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to Delete Group Data', error);
            if (resolve) rejects(error);
        });
    }

    /**
     * get group's roles
     * 
     * @param {string} id 
     * @param resolve 
     * @param rejects 
     */
    private _getGroupRoles(id: string, resolve = null, rejects = null): void {

        this._groups.getGroupRoles(id).subscribe((returnData) => {
            resolve(returnData.data);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to get group roles', error);
            if (resolve) rejects(error);
        });
    }

    /**
     * save new group's role
     * 
     * @param {string} id 
     * @param {RoleRequest} inputData
     * @param resolve 
     * @param rejects 
     */
    private _saveGroupRole(id: string, inputData: RoleRequest, resolve = null, rejects = null): void {

        this._groups.saveGroupRole(id, inputData).subscribe(() => {
            this.getGrouops(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to save group role', error);
            if (resolve) rejects(error);
        });
    }

    /**
     * update group's role
     * 
     * @param {string} id 
     * @param {string} roleId 
     * @param {RoleRequest} inputData
     * @param resolve 
     * @param rejects 
     */
    private _updateGroupRole(id: string, roleId: string, inputData: RoleRequest, resolve = null, rejects = null): void {
        this._groups.updateGroupRole(id, roleId, inputData).subscribe(() => {
            this.getGrouops(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to update group role', error);
            if (resolve) rejects(error);
        });
    }

    /**
     * update group's role
     * 
     * @param {string} id 
     * @param {string} roleId 
     * @param resolve 
     * @param rejects 
     */
    private _deleteGroupRole(id: string, roleId: string, resolve = null, rejects = null): void {
        this._groups.deleteGroupRole(id, roleId).subscribe(() => {
            this.getGrouops(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to delete group role', error);
            if (resolve) rejects(error);
        });
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh Group
     */
    public refreshGroups(): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this.getGrouops(resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Create Group
     * 
     * @param inputData
     * @returns 
     */
    public createGroup(inputData: any): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._createGroup(inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Update Group
     * 
     * @param id
     * @param inputData
     * @returns 
     */
    public updateGroup(id: string, inputData: any): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._updateGroup(id, inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * assign multiple device to one group
     * 
     * @param id
     * @param inputData
     * @returns 
     */
    public assignGroup(id: string, inputData: string[]): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._assignGroup(id, inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * unassign multiple device's group
     * 
     * @param inputData
     * @returns 
     */
    public unassignGroup(inputData: string[]): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._unassignGroup(inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Delete Group By Id
     * 
     * @param id
     * @returns {Promise<any>}
     */
    public deleteGroup(id: string): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._deleteGroup(id, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Get Group Role By Id
     * 
     * @param id
     * @returns {Promise<any>}
     */
    public getGroupRoles(id: string): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._getGroupRoles(id, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Save New Group Role By Id
     * 
     * @param {string} id
     * @param {RoleRequest} inputData
     * @returns {Promise<any>}
     */
    public saveGroupRole(id: string, inputData: RoleRequest): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._saveGroupRole(id, inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Update Group Role By Id
     * 
     * @param {string} id
     * @param {string} roleId
     * @param {RoleRequest} inputData
     * @returns {Promise<any>}
     */
    public updateGroupRole(id: string, roleId: string, inputData: RoleRequest): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._updateGroupRole(id, roleId, inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Delete Group Role By Id
     * 
     * @param {string} id
     * @param {string} roleId
     * @returns {Promise<any>}
     */
    public deleteGroupRole(id: string, roleId: string): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._deleteGroupRole(id, roleId, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Clear the Current User Data
     */
    public clearAllInfo(): void {
        this.currentGroupListSubject.next(null);
    }
}